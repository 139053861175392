<template>
    <div class="browse-datepicker">
        <NebulaDatepicker
            class="browse-datepicker__datepicker"
            :class="{ 'browse-datepicker__datepicker--hairline': hairline }"
            :inline="true"
            :value="selected"
            @input="handleSelection"
        />
    </div>
</template>

<script>
import {
    NebulaDatepicker,
} from '@discoveryedu/nebula-components';

export default {
    name: 'BrowseDatePicker',
    components: {
        NebulaDatepicker,
    },
    props: {
        hairline: {
            type: Boolean,
            default: true,
        },
        selected: {
            type: Date,
        },
    },
    methods: {
        handleSelection(date) {
            this.$emit('date-selected', date);
        },
    },
};
</script>

<style lang="stylus">
.browse-datepicker {
    --nebula-date-picker-calendar-border: none;

    .nebula-date-picker__calendar>div {
        width: 100%;
    }

    &__datepicker {
        margin: -16px -16px 0 -16px;
        --nebula-date-picker-caret-size: 24px;
        --nebula-date-picker-calendar-date-border: 2px solid #ffffff;
        --nebula-icon-size-s: 12px;

        .nebula-date-picker__calendar {
            width: 100%;
            & .picker-view .vdp-datepicker__up {
                margin-left: 0;
            }
            .picker-view .cell {
                height: 33px;
            }
        }
        &--hairline {
            &:after {
                content: '';
                display: block;
                height: 1px;
                background: $nebula-color-platform-interface-300;
                margin-inline: $nebula-space-2x;
                margin-bottom: $nebula-space-4x;
            }
        }
    }
}
</style>
