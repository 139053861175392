<template>
    <div class="attachment-card">
        <div class="attachment-card__visual">
            <img :src="DELogo" v-if="resource.evergreen" class="attachment-card__evergreen-icon">
            <NebulaIcon v-else symbol-id="page" class="attachment-card__icon" size="l" />
        </div>
        <div class="attachment-card__content">
            <div class="attachment-card__description">
                <div class="attachment-card__title-group">
                    <h2 class="attachment-card__title">{{ resource.title }}</h2>
                    <div
                        v-if="showStatus"
                        class="attachment-card__status"
                        :class="`attachment-card__status--${resource.status}`"
                    >
                        <CircleLoader v-if="resource.status === 'uploading'" />
                        <NebulaIcon v-if="status.symbol" :symbol-id="status.symbol" size="s" />
                        <p
                            class="attachment-card__status-text"
                        >
                            {{ status.display }}
                        </p>
                    </div>
                </div>
                <div>
                    <div v-if="showStatus">
                        <div class="attachment-card__loader-bar" :class="`attachment-card__loader-bar--${resource.status}`" />
                    </div>
                    <div v-else class="attachment-card__details-group">
                        <p class="attachment-card__detail" v-if="resource.type">{{ resource.type }}</p>
                        <p class="attachment-card__detail" v-if="resource.fileSize">{{ resource.fileSize }}</p>
                    </div>
                </div>
            </div>
            <div class="attachment-card__actions">
                <NebulaIconButton
                    v-if="resource.isDownloadable"
                    target="_blank"
                    aria-label="download attachment"
                    icon="download"
                    type="flat"
                    :link="resource.downloadUrl"
                    :download="resource.title"
                />
                <div v-if="resource.isOwner">
                    <NebulaIconButton
                        class="attachment-card__delete-button"
                        aria-label="delete attachment"
                        icon="trash"
                        type="flat"
                        @click="deleteAttachment(resource)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    NebulaIcon,
    NebulaIconButton,
} from '@discoveryedu/nebula-components';
import CircleLoader from '@/components/shared/Loader/CircleLoader.vue';
import createPayload from '@/mixins/data/createPayload';

const DELogo = require('@/assets/de-logo_md.png');

export default {
    name: 'AttachmentCard',
    mixins: [createPayload],
    components: {
        CircleLoader,
        NebulaIcon,
        NebulaIconButton,
    },
    props: {
        resource: Object,
    },
    computed: {
        requestId() {
            return this.$store.getters.request.requestId;
        },
        showStatus() {
            return this.resource.status && this.resource.status !== 'staged';
        },
        status() {
            if (!this.resource || !this.resource.status) {
                return null;
            }
            const statusMap = {
                uploading: {
                    display: 'File uploading',
                },
                success: {
                    display: 'File upload success',
                    symbol: 'circle-check--filled',
                },
                error: {
                    display: 'Upload failed',
                    symbol: 'warning--filled',
                },
                staged: {
                    display: 'File staged',
                    symbol: 'warning--filled',
                },
            };

            return statusMap[this.resource.status];
        },
    },
    data() {
        return {
            DELogo,
        };
    },
    methods: {
        deleteAttachment(resource) {
            const { status, title: filename } = resource;
            if (status && status === 'staged') {
                // delete from staged files
                this.$emit('unstage-attachment', filename);
                return;
            }
            // delete from s3
            this.$emit('delete-attachment', filename);
        },
    },
};
</script>

<style lang="stylus">
@keyframes loader-bar {
    0%{
        width: 0;
    }
    100%{
        width: 90%;
    }
}

.attachment-card {
    padded-card-compact();

    display: flex;
    height: 100%;

    &__delete-button {
        --nebula-icon-button-fill: $nebula-color-platform-interface-800;
    }

    &__evergreen-icon {
        width: $nebula-space-4x;
    }

    &__icon {
        fill: $nebula-color-platform-interface-500;
    }

    &__content {
        display: flex;
        flex: 1;
        justify-content: space-between;
    }

    &__visual {
        align-items: center;
        display: flex;
        margin-right: $nebula-space-4x;
    }

    &__title-group {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        nebula-text-caption();
        font-weight: bold;
        margin: 0;
    }

    &__description {
        flex: 1;
        max-width: 420px;
    }

    &__details-group {
        display: flex;
    }

    &__detail {
        nebula-text-caption();
        color: $nebula-color-platform-neutral-700;

        margin: 0;
        margin-block-start: $nebula-space-1x;
        margin-inline-end: $nebula-space-2x;
    }

    &__actions {
        display: flex;
        align-items: center;
    }

    &__loader-bar {
        height: 6px;
        width: 100%;
        background: $nebula-color-platform-interface-300;
        border-radius: 3px;
        margin-block-start: $nebula-space-1x;
        &:after {
            border-radius: 3px;
            content: '';
            display: block;
            height: 100%;
        }
        &--uploading {
            &:after {
                animation: linear .5s 1 both loader-bar;
                background-color: $nebula-color-platform-interactive-800;
            }
        }
        &--success {
            &:after {
                background-color: $nebula-color-feedback-success-700;
                width: 100%;
            }
        }
        &--error {
            &:after {
                background-color: $nebula-color-feedback-error-600;
                width: 80%;
            }
        }
    }

    &__status {
        display: flex;
        align-items: center;
        gap: $nebula-space-1x;
        &--success {
            .attachment-card__status-text {
                color: $nebula-color-feedback-success-700;
            }
            .nebula-icon {
                fill: $nebula-color-feedback-success-700;
            }
        }
        &--error {
            .attachment-card__status-text {
                color: $nebula-color-feedback-error-600;
            }
            .nebula-icon {
                fill: $nebula-color-feedback-error-600;
            }
        }
    }

    &__status-text {
        nebula-text-caption();
        margin: 0;
    }
}
</style>
