<template>
    <div class="message-request-details">
        <RequestDetailsContent
            v-if="!loading && request"
            small
            timeRemaining
            :data="request"
            vertical
        />
        <div>
            <h3 class="message-request-details__subheader">Description</h3>
            <p class="message-request-details__paragraph">{{ request.description }}</p>
        </div>
        <NebulaButton
            routerLink
            class="message-request-details__request-link"
            text="View Details"
            size="l"
            type="ghost"
            :link="`/request/${request.requestId}`"
        />
        <NebulaFormHelper helperId="string" text="*View details to upload resources." />
</div>
</template>

<script>
import {
    NebulaButton,
    NebulaFormHelper,
} from '@discoveryedu/nebula-components';
import RequestDetailsContent from '@/components/shared/requests/RequestDetails.vue';

export default {
    name: 'MessageRequestDetails',
    components: {
        NebulaButton,
        NebulaFormHelper,
        RequestDetailsContent,
    },
    props: {
        request: Object,
        loading: Boolean,
    },
};
</script>

<style lang="stylus">
.message-request-details {
    overflow-y: auto;

    &__request-link {
        width: 100%;
        justify-content: center;
        margin-block-end: $nebula-space-2x;
    }
    &__subheader {
        margin: $nebula-space-2x 0 $nebula-space-1x;
        font-size: $nebula-font-size-mobile-body-1;
    }
    &__paragraph {
        margin: 0 0 $nebula-space-2x;
        font-size: $nebula-font-size-mobile-body-1;
    }
}
</style>
