<template>
    <p class="banner-description" :class="{'banner-description--wide': wide}">
        {{ description }}
    </p>
</template>

<script>
export default {
    name: 'BannerDescription',
    props: {
        description: String,
        wide: Boolean,
    },
};
</script>

<style lang="stylus">
responsive-description() {
    font-size: $nebula-font-size-mobile-header-level-6;
    @media (min-width: $nebula-breakpoints-tablet-portrait) {
        font-size: $nebula-font-size-card-title;
    }
}

.banner-description {
    responsive-description();
    color: $nebula-color-white;
    line-height: $nebula-font-line-height-heading;
    max-width: 430px;
    &--wide {
        max-width: 100%;
    }
}
</style>
