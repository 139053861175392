<template>
    <PageWrapper class="request-details" pageTitle="Request Details" breadcrumbs>
        <template v-slot:banner>
            <NebulaBlockMessage
                v-if="applySuccessful"
                class="request-details__block-message"
                icon="circle-check"
                color="green"
                header="You have successfully applied"
            />
            <NebulaBlockMessage
                v-if="acceptSuccessful"
                class="request-details__block-message"
                icon="circle-check"
                color="green"
                header="Your Connect is scheduled!"
                description="You can now connect with the Educator via messaging to ask questions and learn more about the Connect."
            />
            <WithinNextN v-if="!isScheduled && request.startDateTime" :timestamp="request.startDateTime">
                <NebulaBlockMessage
                    class="request-details__block-message"
                    icon="circle-check"
                    header="This request is scheduled within the next 24 hours"
                >
                    This educator may not be available at this scheduled time.
                </NebulaBlockMessage>
            </WithinNextN>
        </template>
        <template v-slot:actions>
            <NebulaButton
                v-if="showCancel"
                class="request-details__cancel-button"
                type="flat"
                text="Cancel"
                @click="handleCancelRequest"
            />
            <NebulaButton
                v-if="showApply"
                :isDisabled="disableApply"
                text="Apply"
                @click="confirmApplyToRequest"
                data-click-type="Open Request Details: Connector"
                data-click-name="Apply"
            />
            <NebulaButton
                v-if="showConfirmDecline"
                text="Decline"
                type="flat"
                @click="handleDeclineRequest"
                data-click-type="Direct Request Details: Connector"
                data-click-name="Decline"
            />
            <NebulaButton
                v-if="showConfirmDecline"
                :isDisabled="loadingSubmit"
                :text="loadingSubmit ? 'Processing': 'Accept'"
                @click="handleConfirmRequest"
                data-click-type="Direct Request Details: Connector"
                data-click-name="Accept"
            />
        </template>
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12">
                <GrayBoxLoader v-if="loading.request" card />
                <RequestDetailCard
                    v-else-if="request"
                    :request="request"
                    :applicationOpen="applicationOpen"
                >
                    <template v-slot:actions>
                        <ParticipantCard
                            v-if="!loading.request && showConnector"
                            :participantType="EMPLOYEE_ROLE"
                            class="request-detail-card__participant-card"
                            :request="request"
                        />
                        <ParticipantCard
                            v-if="!loading.request"
                            :participantType="EDUCATOR_ROLE"
                            class="request-detail-card__participant-card"
                            :request="request"
                        />
                    </template>
                </RequestDetailCard>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12">
                <PageSection class="request-details__detail-section">
                    <RequestContent :loading="loading.request" />
                </PageSection>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12">
                <UploadSection
                    :requestId="requestId"
                    :requestOwnerId="requestOwnerId"
                />
            </NebulaColumn>
        </NebulaGridRow>
    </PageWrapper>
</template>

<script>
import {
    NebulaGrid,
    NebulaButton,
    NebulaBlockMessage,
} from '@discoveryedu/nebula-components';
import { RequestDetailCard, RequestContent } from '@/components/shared/requests';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';
import PageSection from '@/components/shared/layout/PageSection.vue';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import ParticipantCard from '@/components/shared/ParticipantCard.vue';
import WithinNextN from '@/components/shared/utilities/WithinNextN.vue';
import UploadSection from '@/components/resources/UploadSection.vue';

import scrollToTop from '@/mixins/scrollToTop';
import application from '@/mixins/requests/application';

import createPayload from '@/mixins/data/createPayload';
import requests from '@/mixins/requests';

import {
    roles,
    ADMIN_ROLES,
    partnerRoles,
    pendingMatches,
    actionRequired,
    awaitingConfirmation,
    scheduled,
} from '@/constants';

export default {
    name: 'EmployeeRequestDetails',
    components: {
        NebulaBlockMessage,
        NebulaButton,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
        PageWrapper,
        RequestDetailCard,
        RequestContent,
        PageSection,
        GrayBoxLoader,
        ParticipantCard,
        WithinNextN,
        UploadSection,
    },
    mixins: [createPayload, scrollToTop, application, requests],
    computed: {
        loadingSubmit() {
            return this.$store.state.status.request_direct_submit.loading;
        },
        appMode() {
            return this.$store.state.app.mode;
        },
        showConnector() {
            return !!this.isAdmin && this.request.employeeId;
        },
        request() {
            return this.$store.state.page.request;
        },
        isConnector() {
            return this.appMode === roles.EMPLOYEE_ROLE;
        },
        isPartner() {
            return partnerRoles.includes(this.appMode);
        },
        applicationPending() {
            return !!this.userApplication && !this.userApplication.directRequest;
        },
        requestOpen() {
            if (!this.request) {
                return false;
            }
            return this.request.status === pendingMatches || this.request.status === actionRequired;
        },
        actionRequired() {
            if (!this.request) {
                return false;
            }

            return this.request.status === awaitingConfirmation;
        },
        status() {
            return this.request ? this.request.status : '';
        },
        applications() {
            return this.$store.state.page.applications;
        },
        currentUserRequested() {
            if (this.appMode !== roles.EMPLOYEE_ROLE) {
                // this will always be false for org admins
                return false;
            }

            // "direct requesting" an employee also adds the request to the "applications" list for that user,
            // just with directRequest set to true
            return !!this.userApplication && this.userApplication.directRequest;
        },
        currentUserScheduled() {
            if (this.appMode !== roles.EMPLOYEE_ROLE) {
                return false;
            }

            if (this.request.status !== scheduled) {
                return false;
            }

            return this.request.employeeId === this.$store.getters.userId;
        },
        applySuccessful() {
            return this.$store.getters.applyResult === 'success';
        },
        acceptSuccessful() {
            return this.$store.getters.acceptDirectRequestResult === 'success';
        },
        isScheduled() {
            return this.request.status === scheduled && !!this.request.employeeId;
        },
        isAdmin() {
            return ADMIN_ROLES.includes(this.appMode);
        },
        showCancel() {
            if (this.appMode === roles.ORGADMIN_ROLE) {
                return false;
            }

            // employees can only cancel scheduled requests
            return !!this.currentUserScheduled;
        },
        showConfirmDecline() {
            if (!this.isConnector || !this.requestDirectFF) {
                return false;
            }

            if (this.isScheduled) {
                return false;
            }

            return !!this.currentUserRequested && this.actionRequired;
        },
        // feature flags
        requestEditFF() {
            return this.$store.getters.requestEditFF;
        },
        requestApplyFF() {
            return this.$store.getters.requestApplyFF;
        },
        requestDirectFF() {
            return this.$store.getters.requestDirectFF;
        },
    },
    data() {
        return {
            EDUCATOR_ROLE: roles.EDUCATOR_ROLE,
            EMPLOYEE_ROLE: roles.EMPLOYEE_ROLE,
            confirmation: null,
            filteredApplicants: [],
            requestedEmployeeId: null,
            disableApply: true,
            showApply: false,
            loading: {
                attachments: true,
            },
        };
    },
    methods: {
        confirmApplyToRequest() {
            const { educatorFirstName, educatorLastName, requestId } = this.request;
            const educatorName = `${educatorFirstName} ${educatorLastName}`;
            this.$store.dispatch('toggleModal', {
                category: 'request',
                type: 'apply',
                show: !this.$store.getters.requestApplyModal.show,
                educatorName,
                requestId,
            });
        },
        async handleCancelRequest() {
            const { requestId, status: requestStatus } = this.request;
            this.$store.dispatch('toggleModal', {
                category: 'request',
                type: 'cancel',
                show: !this.$store.getters.requestCancelModal.show,
                requestId,
                requestStatus,
            });
        },
        async handleDeclineRequest() {
            const { requestId } = this.request;
            this.$store.dispatch('toggleModal', {
                category: 'request',
                type: 'decline-direct',
                show: !this.$store.getters.requestDeclineDirectModal.show,
                requestId,
            });
        },
        async handleConfirmRequest() {
            this.$store.dispatch('updateLoading', { key: 'request_direct_submit', status: true });

            const payload = await this.createPayload({ requestId: this.requestId });
            await this.$store.dispatch('confirmDirectRequest', payload);

            // enable messaging after a request transitions to scheduled
            // const messagePayload = await this.createPayload({ requestId: this.requestId });
            // this.$store.dispatch('createMessageStore', messagePayload);

            this.$store.dispatch('updateLoading', { key: 'request_direct_submit', status: false });
        },
        async declineDirectRequest() {
            this.$store.dispatch('updateLoading', { key: 'request_direct_submit', status: true });

            const payload = await this.createPayload({ requestId: this.request.requestId });
            await this.$store.dispatch('declineDirectRequest', payload);
            this.$store.dispatch('updateLoading', { key: 'request_direct_submit', status: false });
        },
        updateApplicationState() {
            // if user is requested but the status is pendingMatches or actionRequired
            // the direct request is not currently active
            if (this.currentUserRequested && !this.requestOpen) {
                return;
            }

            if (this.applicationDeclined || this.applySuccessful || this.applicationPending) {
                this.disableApply = true;
                return;
            }

            if (this.requestOpen) {
                this.disableApply = false;
            }
        },
        updateShowApply() {
            if (this.appMode === roles.ORGADMIN_ROLE) {
                return;
            }

            if (this.requestApplyFF && this.requestOpen) {
                this.showApply = true;
            }
        },
        async update() {
            // triggered by parent view if the currently-viewed request changes
            await this.getSingleRequest();
            await this.getApplications();

            this.updateApplicationState();
            this.updateShowApply();
        },
    },
    async mounted() {
        this.update();
    },
    async beforeDestroy() {
        await this.$store.dispatch('updateResults', { key: 'request_apply', status: null });
        await this.$store.dispatch('updateResults', { key: 'request_direct_accept', status: null });
    },
    watch: {
        applySuccessful() {
            this.updateApplicationState();
            this.updateShowApply();
        },
    },
};
</script>

<style lang="stylus">
.request-details {
    &__block-message {
        width: 100%;
        max-width: 100%;
        margin-block-start: $nebula-space-2x;
    }

    &__detail-section {
        --nebula-icon-button-padding: 0;
        .nebula-icon-button--l {
            --nebula-icon-button-padding: 0;
            --nebula-icon-button-background-hover: transparent;
            --nebula-icon-button-fill: $nebula-color-platform-interface-1000;
            --nebula-icon-button-fill-hover: $nebula-color-platform-interactive-800;
            .nebula-icon-button__icon {
                height: 24px;
                width: 24px;
            }
        }
    }

    &__card-action-button {
        flex: 1;
        display: flex;
        justify-content: center;
    }

    &__request-button {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
</style>
