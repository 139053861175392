<template>
    <NebulaPageWrapper class="page-wrapper" contained padded ref="pageWrapper">
        <ErrorBanner
            v-if="pageError"
            :header="pageError.header || 'Something went wrong'"
            :description="pageError.description"
            color="red"
        />
        <BreadcrumbsRow v-if="breadcrumbs" />
        <NebulaGridRow v-if="pageTitle" type="flex">
            <NebulaColumn :columns="12">
                <slot name="banner" />
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow v-if="pageTitle" type="flex">
            <NebulaColumn :columns="12">
                <PageHeader :title="pageTitle">
                    <template v-slot:actions>
                        <slot name="actions" />
                    </template>
                </PageHeader>
            </NebulaColumn>
        </NebulaGridRow>
        <slot />
    </NebulaPageWrapper>
</template>

<script>
import { NebulaGrid } from '@discoveryedu/nebula-components';
import BreadcrumbsRow from '@/components/shared/layout/BreadcrumbsRow.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import ErrorBanner from '@/components/shared/ErrorBanner.vue';

import scrollToTop from '@/mixins/scrollToTop';

export default {
    name: 'PageWrapperNew',
    components: {
        NebulaPageWrapper: NebulaGrid.NebulaPageWrapper,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
        BreadcrumbsRow,
        PageHeader,
        ErrorBanner,
    },
    mixins: [scrollToTop],
    props: {
        pageTitle: String,
        contained: {
            type: Boolean,
            default: true,
        },
        breadcrumbs: Boolean,
    },
    computed: {
        pageError() {
            return this.$store.state.page.error;
        },
    },
    mounted() {
        this.scrollToTop();
    },
    // beforeDestroy() {
    //     this.$store.dispatch('resetPageData');
    // },
};
</script>

<style lang="stylus">
.page-wrapper {
    flex: 1;
}
</style>
