<template>
    <div>
        <NebulaTitleBlock
            v-if="header"
            type="section"
            class="page-subheader__title"
            :title="header"
        />
        <p class="page-subheader__description" v-if="description">
            {{ description }}
        </p>
    </div>
</template>

<script>
import { NebulaTitleBlock } from '@discoveryedu/nebula-components';

export default {
    name: 'PageSubheader',
    components: {
        NebulaTitleBlock,
    },
    props: {
        header: String,
        description: String,
    },
};
</script>

<style lang="stylus">
.page-subheader {
    &__title {
        --nebula-font-size-header-level-5: $nebula-font-size-card-title;
    }

    &__description {
        nebula-text-body-1();
        margin: $nebula-space-half 0 0;
        --nebula-text-color-body-default: $nebula-color-platform-neutral-900;
    }
}
</style>
