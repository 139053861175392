<template>
    <div class="dots-pagination">
        <button
            v-for="(n, idx) in length" class=""
            :class="[
                'dots-pagination__dot',
                { 'dots-pagination__dot--active': idx === current }]"
            :aria-label="`view banner ${idx}`"
            :key="`dot-${idx}`"
            :disabled="idx === current"
            @click="handleClick(n - 1)"
        />
    </div>
</template>

<script>
export default {
    name: 'DotsPagination',
    props: {
        length: {
            type: Number,
            required: true,
        },
        current: {
            type: Number,
        },
    },
    methods: {
        handleClick(idx) {
            this.$emit('dot-clicked', idx);
        },
    },
};
</script>

<style lang="stylus">
$pagination-dot-size = $nebula-space-2x;

.dots-pagination {
    display: flex;
    gap: $nebula-space-2x;
    justify-content: center;
    margin-top: $nebula-space-2x;

    &__dot {
        border: none;
        width: $pagination-dot-size;
        height: $pagination-dot-size;
        border-radius: 50%;
        background: $nebula-color-platform-interface-600;
        opacity: 50%;
        &--active {
            background: $nebula-color-platform-interface-600;
            opacity: 1;
        }
    }
}
</style>
