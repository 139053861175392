<template>
    <img :src="circleLoader" class="circle-loader">
</template>
<script>

const circleLoader = require('@/assets/circle-loader.png');

export default {
    name: 'CircleLoader',
    data() {
        return {
            circleLoader,
        };
    },
};
</script>

<style lang="stylus">
@keyframes rotate {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
.circle-loader {
    animation: linear 1s infinite both rotate;
    height: 16px;
    width: 16px;
}
</style>
