<template>
    <div class="message-card">
        <div class="message-card__id-line">
            <div class="message-card__name-group">
                <ProfileAvatar :image="user && user.avatarUrl" size="s" />
                <h3
                    class="message-card__name"
                    :class="{'message-card__name--self': data.self}"
                >
                    {{ user.displayName }}
                </h3>
            </div>
            <p>{{ semanticTime }}</p>
        </div>
        {{ data.message }}
    </div>
</template>

<script>
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';
import timeFromNow from '@/utils/timeRemaining';
import { timestampNow } from '@/data/placeholder/date';

export default {
    name: 'MessageCard',
    components: {
        ProfileAvatar,
    },
    props: {
        data: Object,
        user: Object,
        partner: Object,
    },
    data() {
        return {
            semanticTime: timeFromNow(this.data.timestamp),
            reRender: null,
            // default to updating relative time every minute
            interval: 60000,
        };
    },
    methods: {
        reRenderTime() {
            this.reRender = setInterval(() => {
                this.semanticTime = timeFromNow(this.data.timestamp);
            }, this.interval);
        },
    },
    created() {
        const timeDiff = timestampNow() - this.data.timestamp;

        if (timeDiff > 86400) {
            // don't initiate the regular rerender if message is over a day old
            return;
        }
        if (timeDiff > 3600) {
            // over an hour ago, only check every 30 mins
            this.interval = (3600 * 1000) / 2;
        }

        this.reRenderTime();
    },
    beforeDestroy() {
        if (this.reRender) {
            clearInterval(this.reRender);
        }
    },
};
</script>

<style lang="stylus">
.message-card {
    margin-block-end: $nebula-space-2x;

    &__id-line {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__name-group {
        display: flex;
        align-items: center;
        gap: $nebula-space-2x;
    }

    &__name {
        font-weight: 600;
        &--self {
            color: $nebula-color-interactive-blue-300;
        }
    }
}
</style>
