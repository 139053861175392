<template>
    <PageWrapper>
        <NebulaGridRow v-if="showCarousel">
            <NebulaGridBlock :columns="12">
                <BannerCarousel />
            </NebulaGridBlock>
        </NebulaGridRow>
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12">
                <PageSection title="Connectors" :viewAll="{ show: true, link: '/connectors' }">
                    <GrayBoxLoader cardRow v-if="loading.connectors" />
                    <NebulaGridRow v-else gutter>
                        <NebulaGridBlock :columns="3" :mdSize="4" :smSize="4" v-for="(connector, idx) in connectors" :key="`match-${idx}`">
                            <CardProfile :data="connector" />
                        </NebulaGridBlock>
                    </NebulaGridRow>
                </PageSection>
                <PageSection title="Organizations"
                    :viewAll="{ show: organizations, link: '/organizations' }"
                >
                    <GrayBoxLoader cardRow v-if="loading.organizations" />
                    <NebulaGridRow v-else gutter>
                        <NebulaGridBlock :columns="3" :mdSize="4" :smSize="4" v-for="(organization, idx) in organizations" :key="idx">
                            <CardProfile
                                :data="organization"
                                buttonText="View Profile"
                                :buttonLink="`organization/${organization.organizationId}`"
                            />
                        </NebulaGridBlock>
                    </NebulaGridRow>
                </PageSection>
                <ResourcesRow
                    title="Content"
                    topic="recommended"
                    :max="4"
                    :viewAll="{ show: true, link: '/content' }"
                />
            </NebulaColumn>
        </NebulaGridRow>
    </PageWrapper>
</template>

<script>
import { NebulaGrid } from '@discoveryedu/nebula-components';

import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';
import PageSection from '@/components/shared/layout/PageSection.vue';
import CardProfile from '@/components/shared/cards/CardProfile.vue';
import ResourcesRow from '@/components/resources/ResourcesRow.vue';
import BannerCarousel from '@/components/shared/BannerCarousel.vue';

import createPayload from '@/mixins/data/createPayload';
import scrollToTop from '@/mixins/scrollToTop';

export default {
    name: 'BrowseAll',
    components: {
        BannerCarousel,
        CardProfile,
        PageWrapper,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        NebulaColumn: NebulaGrid.NebulaColumn,
        PageSection,
        ResourcesRow,
        GrayBoxLoader,
    },
    mixins: [createPayload, scrollToTop],
    computed: {
        connectors() {
            return this.$store.state.page.employees;
        },
        organizations() {
            return this.$store.state.page.organizations;
        },
        spotlightBannerFF() {
            return this.$store.getters.spotlightBannerFF;
        },
        flags() {
            return this.$store.getters.flags;
        },
        showCarousel() {
            // const currentSpotlight = this.$store.state.app.spotlight;
            // const bannerActive = currentSpotlight && currentSpotlight.banner && currentSpotlight.banner.active;
            return this.flags.carousel;
        },
        spotlight() {
            return this.$store.state.app.spotlight;
        },
    },
    data() {
        return {
            loading: {
                connectors: true,
                organizations: true,
            },
        };
    },
    async mounted() {
        this.scrollToTop();

        const maxPerRow = 4;

        const rawConnectorParams = [['limit', maxPerRow], ['sort', 'random']];
        const connectorParams = new URLSearchParams(rawConnectorParams).toString();

        const connectorPayload = await this.createPayload({ params: connectorParams });
        const orgPayload = await this.createPayload({ max: maxPerRow });

        const getConnectors = async () => {
            await this.$store.dispatch('getEmployees', connectorPayload);
            this.loading.connectors = false;
        };

        const getOrganizations = async () => {
            await this.$store.dispatch('getOrganizations', orgPayload);
            this.loading.organizations = false;
        };

        await Promise.all(
            [
                getConnectors(),
                getOrganizations(),
            ],
        );
    },
};
</script>
