<template>
    <div :class="className">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ProfileFieldGroup',
    computed: {
        className() {
            return [
                'profile-field-group',
                {
                    'profile-field-group--horizontal': this.horizontal,
                    'profile-field-group--padded': this.padded,
                },
            ];
        },
    },
    props: {
        horizontal: {
            type: Boolean,
            default: false,
        },
        padded: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang='stylus'>
.profile-field-group {
    &--horizontal {
        display: flex;
        flex-wrap: wrap;
        gap: 0 $nebula-space-3x;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            flex-wrap: nowrap;
        }
    }

    &--padded {
        margin-block-start: $nebula-space-5x;
    }
}
</style>
