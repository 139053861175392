<template>
    <div
        :class="[
        'page-button__container',
        `page-button--${type}`,
        { 'page-button--disabled': isDisabled }]"
        >
        <button
            class="page-button"
            @click="emitEvent"
        >
            <NebulaIcon class="careers__icon" :symbolId="icon" />
        </button>
    </div>
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';

export default {
    name: 'PageButton',
    components: {
        NebulaIcon,
    },
    computed: {
        icon() {
            if (this.type === 'prev') {
                return 'caret-left';
            }

            return 'caret-right';
        },
    },
    props: {
        min: {
            type: Number,
        },
        max: {
            type: Number,
        },
        isDisabled: Boolean,
        something: String,
        type: {
            type: String,
            default: 'next',
        },
    },
    methods: {
        emitEvent() {
            this.$emit('page-change', this.type);
        },
    },
};
</script>

<style lang="stylus">
$page-button-size=48px;
$page-button-size-mobile=40px;

.page-button {
    &--prev {
        left: $nebula-space-1x;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            left: $nebula-space-2x;
        }
    }

    &--next {
        right: $nebula-space-1x;
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            right: $nebula-space-2x;
        }
    }

    &__container {
        height: 100%;
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    --nebula-card-carousel-controls-size: $page-button-size-mobile;
    @media (min-width: $nebula-breakpoints-desktop) {
        --nebula-card-carousel-controls-size: $page-button-size;
    }

    border: none;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--nebula-card-carousel-controls-size);
    width: var(--nebula-card-carousel-controls-size);
    background-color: var(--nebula-card-carousel-controls-background-color);
    transition: var(--nebula-card-carousel-controls-transition-hover);

    &:hover, &:focus-visible {
        background-color: var(--nebula-card-carousel-controls-background-color-hover);
    }

    svg {
        fill: $nebula-color-platform-white;
    }

    &--disabled {
        --nebula-card-carousel-controls-background-color: var(--nebula-card-carousel-controls-background-color-hover);
        opacity: .5;
        pointer-events: none;
    }
}
</style>
