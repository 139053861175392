<template>
    <div>
        <ErrorBanner
            v-if="showErrors && fieldsWithErrors.length > 0"
            header="Form Errors"
            description="Please resolve the form errors in order to proceed to the next step."
            color="red"
        />
        <ErrorBanner
            v-if="pageError"
            :header="pageError.header || 'Something went wrong'"
            :description="pageError.description"
            color="red"
        />
        <div class="new-request__action-row" v-if="!isEdit">
            <NebulaButton
                :isDisabled="loadingSaveDraft"
                :text="$t('save-request-draft', { ns: 'requestcreate'})"
                type="ghost"
                @click="handleSaveDraft"
                :data-click-type="isEdit ? 'Edit Request: Educator' : 'New Request: Educator'"
                data-click-name="Save Draft"
            />
        </div>
        <section>
            <ProfileFormField
                name="title"
                :inputGroupData="formData.title"
                :validation="validateInput"
            />
            <ProfileFormField
                name="type"
                inputType="select"
                :inputGroupData="formData.type"
                :selectOptions="typeOptions"
                :validation="validateInput"
            />
            <ProfileFormField
                name="date"
                inputType="datepicker"
                :inputGroupData="formData.date"
                :validation="validateInput"
            />
            <ProfileFieldGroup horizontal>
                <ProfileFormField
                    name="timeStart"
                    :inputGroupData="formData.timeStart"
                    :validation="validateInput"
                    :selectOptions="startOptions"
                    @update-select="handleStartTimeSelected"
                    helperText="*All times are reflected in your local time zone."
                />
                <ProfileFormField
                    name="timeEnd"
                    :inputGroupData="formData.timeEnd"
                    :validation="validateInput"
                    :selectOptions="endOptions"
                />
            </ProfileFieldGroup>
            <ProfileFormField
                name="primarySubject"
                :inputGroupData="formData.primarySubject"
                :validation="validateInput"
                :selectOptions="tagOptions"
                @update-select="handlePrimarySelected"
                :disabled="{ disabled: isEdit }"
            />
            <ProfileFormField
                :disabled="{ isDisabled: isEdit }"
                inputType="multi-select"
                name="subjects"
                :key="formData.subjects.value.length"
                :inputGroupData="formData.subjects"
                :validation="validateInput"
                :selectOptions="additionalSubjectOptions"
                helperText="These additional subjects will support in best matching to employees"
                @update-multi-select="handleMultiSelect"
            />
            <ProfileFormField
                name="gradeLevel"
                :inputGroupData="formData.gradeLevel"
                :validation="validateInput"
                :selectOptions="gradeLevelOptions"
            />
            <ProfileFormField
                name="classSize"
                :inputGroupData="formData.classSize"
                :validation="validateInput"
            />
            <ProfileFormField
                name="description"
                inputType="textarea"
                :inputGroupData="formData.description"
                :validation="validateInput"
            />
            <ProfileFormField
                name="meeting_link"
                :inputGroupData="formData.meeting_link"
                :helperText="$t('request-meeting-helper', { ns: 'requestcreate' })"
                :validation="validateInput"
            />
        </section>
        <UploadSection
            v-if="flags.attachments"
            formVersion
            :newMode="!isEdit"
            :requestId="requestId"
            :requestOwnerId="requestOwnerId"
            ref="uploadSection"
        />
        <section class="request-form__actions">
            <NebulaButton
                v-if="!loadingSubmit"
                type="ghost"
                text="Cancel"
                link="/"
                :data-click-type="isEdit ? 'Edit Request: Educator' : 'New Request: Educator'"
                data-click-name="Cancel"
            />
            <NebulaButton
                :isDisabled="loadingSubmit"
                :text="loadingSubmit ? 'Processing' : 'Submit'"
                @click="confirmSubmit"
                :data-click-type="isEdit ? 'Edit Request: Educator' : 'New Request: Educator'"
                data-click-name="Submit Request"
            />
        </section>
    </div>
</template>

<script>
import {
    NebulaButton,
} from '@discoveryedu/nebula-components';

import ErrorBanner from '@/components/shared/ErrorBanner.vue';
import ProfileFormField from '@/components/shared/Profile/ProfileFormField.vue';
import ProfileFieldGroup from '@/components/shared/Profile/ProfileFieldGroup.vue';
import UploadSection from '@/components/resources/UploadSection.vue';

import validation from '@/mixins/form/validation';
import createPayload from '@/mixins/data/createPayload';
import scrollToTop from '@/mixins/scrollToTop';

import { typeOptions, tagOptions, gradeLevelOptions } from '@/data/formData';
import formatTime from '@/utils/formatTime';
import { draft, pendingMatches } from '@/constants';
import { cleanDate } from '@/data/placeholder/date';

export default {
    name: 'RequestForm',
    components: {
        ErrorBanner,
        NebulaButton,
        ProfileFormField,
        ProfileFieldGroup,
        UploadSection,
    },
    mixins: [validation, createPayload, scrollToTop],
    props: {
        data: Object,
    },
    computed: {
        tagOptions() {
            const options = tagOptions();
            return options.map((each) => ({
                ...{ label: each.text },
                ...each,
            }));
        },
        startOptions() {
            return this.createTimeIncrements();
        },
        isEdit() {
            return !!this.data;
        },
        requestId() {
            return this.$store.state.page.request.requestId;
        },
        requestOwnerId() {
            return this.$store.getters.request.educatorId;
        },
        hasConnector() {
            return !!this.data.employeeId;
        },
        loadingSubmit() {
            return this.$store.state.status.request_submit.loading;
        },
        loadingSaveDraft() {
            return this.$store.state.status.request_save_draft.loading;
        },
        pageError() {
            return this.$store.state.page.error;
        },
        flags() {
            return this.$store.getters.flags;
        },
    },
    data() {
        return {
            draftSelected: false,
            status: {
                pendingMatches,
            },
            gradeLevelOptions,
            typeOptions,
            endOptions: this.createTimeIncrements(7, 19),
            additionalSubjectOptions: [],
            formData: {
                title: {
                    value: '',
                    display: 'Title',
                    required: true,
                    errorString: null,
                    error: null,
                    requiredError: 'Provide a short headline for your request—i.e. subject, topic, what you\'re looking for.',
                    status: '',
                    placeholder: 'Enter your request title here',
                },
                type: {
                    value: '',
                    display: 'Type',
                    required: true,
                    errorString: null,
                    error: null,
                    status: '',
                },
                date: {
                    value: '',
                    display: 'Date',
                    required: true,
                    errorString: null,
                    error: null,
                    status: '',
                    placeholder: 'MM/DD/YYYY',
                    validation: {
                        number: {
                            function: this.validateDate,
                            errorString: 'Please select a date in the future',
                        },
                    },
                },
                timeStart: {
                    value: '',
                    display: 'Start Time',
                    required: true,
                    errorString: null,
                    error: null,
                    status: '',
                    validation: {
                        date: {
                            function: this.validateDate,
                            errorString: 'Please select a date and time in the future',
                        },
                    },
                },
                timeEnd: {
                    value: '',
                    display: 'End Time',
                    required: true,
                    errorString: null,
                    error: null,
                    status: '',
                },
                primarySubject: {
                    value: '',
                    display: 'Primary Subject',
                    required: true,
                    errorString: null,
                    error: null,
                    status: '',
                },
                subjects: {
                    value: [],
                    display: 'Additional Subject Area(s)',
                    required: false,
                    error: null,
                    status: '',
                },
                gradeLevel: {
                    value: '',
                    display: 'Grade Level',
                    required: false,
                    error: null,
                    status: '',
                },
                classSize: {
                    value: '',
                    display: 'Classroom Size',
                    required: true,
                    error: null,
                    status: '',
                    validation: {
                        number: {
                            function: this.validateNumber,
                            errorString: 'Must be a number',
                        },
                    },
                },
                description: {
                    value: '',
                    display: 'Description',
                    required: true,
                    error: null,
                    status: '',
                    maxLength: 2400,
                    placeholder: this.$t('request-description-placeholder', { ns: 'requestcreate' }),
                },
                meeting_link: {
                    value: '',
                    display: 'Meeting Link',
                    required: true,
                    requiredError: this.$t('meeting-required-error', { ns: 'requestcreate' }),
                    error: null,
                    status: '',
                    placeholder: 'Ex. zoom.com/link',
                },
                // // hidden field, this is edited by other processes, not a text input
                // status: {
                //     value: '',
                // },
            },
        };
    },
    methods: {
        handleMultiSelect(data) {
            this.formData[data.name].value = data.values;
        },
        handlePrimarySelected() {
            const selected = this.formData.primarySubject.value;

            // reset to handle if one has been selected before
            this.additionalSubjectOptions = this.tagOptions;

            // if newly selected primary subject has already been included as an "additional subject", remove it
            const primaryInAdditional = this.formData.subjects.value.indexOf(selected);
            if (primaryInAdditional >= 0) {
                this.formData.subjects.value.splice(primaryInAdditional, 1);
            }
            const primaryIdx = this.additionalSubjectOptions.findIndex((each) => each.value === selected);
            this.additionalSubjectOptions = this.additionalSubjectOptions.toSpliced(primaryIdx, 1);
        },
        populateEndOptions() {
            // check end time is within reasonable range
            // safety in case of bad data
            // TODO remove when no longer needed
            const { value: timeStart } = this.formData.timeStart;
            const { value: timeEnd } = this.formData.timeEnd;

            const { hours, minutes } = this.getMinutesAndHours(timeStart);
            const { hours: endHours, minutes: endMinutes } = this.getMinutesAndHours(timeEnd);

            const baseHours = hours + (minutes / 60);
            const baseEndHours = endHours + (endMinutes / 60);

            const endOptions = this.createTimeIncrements(baseHours + 0.5, baseHours + 3);

            if (baseEndHours < baseHours) {
                // check end time is within reasonable range
                // safety in case of bad data
                // TODO remove when no longer needed
                const [autoSelection] = endOptions;
                this.formData.timeEnd.value = autoSelection.value;
                this.sendFix();
            }

            this.endOptions = endOptions;
        },
        handleStartTimeSelected() {
            const { value: timeStart } = this.formData.timeStart;
            const { hours, minutes } = this.getMinutesAndHours(timeStart);

            const baseHours = hours + (minutes / 60);
            const endOptions = this.createTimeIncrements(baseHours + 0.5, baseHours + 3);

            // select can get strange with what value is selected when the options are changed
            // so reset the end time every time the start is changed
            const [autoSelection] = endOptions;
            this.formData.timeEnd.value = autoSelection.value;
            this.validateInput('timeEnd');
            this.endOptions = endOptions;
        },
        validateNumber(name) {
            const { value } = this.formData[name];

            const int = parseInt(value, 10);

            return !Number.isNaN(int);
        },
        validateDate(name) {
            const { date: { value: date }, timeStart: { value: timeStart } } = this.formData;
            const now = new Date();
            if (timeStart && timeStart !== '') {
                const startDateTime = this.convertTimestampToEpoch(date, timeStart) * 1000;
                const nowDate = now.getTime();

                if (startDateTime < nowDate) {
                    this.formData[name].error = true;
                    return false;
                }

                if (name === 'date') {
                    this.validateInput('timeStart');
                }
            } else if (date) {
                const selectedDate = cleanDate(date).getTime();
                const nowDate = cleanDate(now).getTime();

                if (selectedDate < nowDate) {
                    return false;
                }
            }

            return true;
        },
        getMinutesAndHours(string) {
            const regex = new RegExp('(\\d{2}):(\\d{2})', 'g');
            const match = regex.exec(string);

            if (!match) {
                return null;
            }
            const hours = parseInt(match[1], 10);
            const minutes = parseInt(match[2], 10);

            return { hours, minutes };
        },
        convertTimestampToEpoch(date, timeStamp) {
            const dateBase = new Date(date);

            const { hours, minutes } = this.getMinutesAndHours(timeStamp);

            dateBase.setHours(hours);
            dateBase.setMinutes(minutes);

            return Math.floor(dateBase.getTime() / 1000);
        },
        convertSelectionsToEpochs(date, timeStart, timeEnd) {
            return {
                startDateTime: this.convertTimestampToEpoch(date, timeStart),
                endDateTime: this.convertTimestampToEpoch(date, timeEnd),
            };
        },
        async confirmSubmit() {
            let changed = false;
            let rescheduled = false;

            // run validation (from mixin) first. This also populates the validation results
            await this.handleSaveAndContinue(true);

            // bail if validation didn't pass
            if (this.fieldsWithErrors && this.fieldsWithErrors.length > 0) {
                return;
            }
            const toSend = this.validationResults;
            let formattedSubmission = this.getRequestToSend(toSend);

            if (this.isEdit) {
                // EXISTING REQUEST

                const isStoredDraft = !this.data.status || this.data.status === draft;

                const { startDateTime, endDateTime } = this.data;
                const { date, timeStart, timeEnd } = this.epochsToTimeAndDate(startDateTime, endDateTime);

                const storedSchedule = {
                    date,
                    timeStart,
                    timeEnd,
                };

                // detect a change in timing and prioritize confirming that
                rescheduled = !!Object.keys(storedSchedule).find((key) => {
                    if (key === 'date') {
                        return storedSchedule[key].toString() !== toSend[key].toString();
                    }
                    return storedSchedule[key] !== toSend[key];
                });

                // detect other field changes
                const compareFields = this.overlapFields(this.data, toSend);

                changed = !!compareFields.find((field) => {
                    if (field === 'classSize') {
                        return this.data[field].toString() !== toSend[field];
                    }
                    return this.data[field] !== toSend[field];
                });

                if (isStoredDraft) {
                    // detected draft in edit mode, update status
                    toSend.status = pendingMatches;
                } else {
                    // otherwise keep status the same
                    toSend.status = this.data.status;
                }

                formattedSubmission = this.getRequestToSend(toSend);

                if (rescheduled) {
                    // prioritize confirming timing changes
                    this.$store.dispatch('toggleModal', {
                        category: 'request',
                        type: 'reschedule',
                        show: true,
                        hasConnector: this.hasConnector,
                        requestToSend: { ...formattedSubmission, ...{ requestId: this.data.requestId } },
                    });
                } else if (changed) {
                    this.$store.dispatch('toggleModal', {
                        category: 'request',
                        type: 'edit',
                        show: true,
                        requestToSend: { ...formattedSubmission, ...{ requestId: this.data.requestId } },
                    });
                } else {
                    // NO CHANGES
                    if (isStoredDraft) {
                        this.$store.dispatch('updateLoading', { key: 'request_submit', status: true });

                        formattedSubmission = this.getRequestToSend(toSend);

                        await this.sendData({ ...formattedSubmission, ...{ requestId: this.data.requestId } });
                        const { requestId } = this.$store.state.page.request;
                        this.$router.push(`/request/${requestId}`);
                    }
                    const { requestId } = this.$store.state.page.request;
                    this.$router.push(`/request/${requestId}`);
                }
            } else {
                // NEW REQUEST
                // new requests are automatically set to this status when submitted
                this.$store.dispatch('updateLoading', { key: 'request_submit', status: true });

                toSend.status = pendingMatches;
                formattedSubmission = this.getRequestToSend(toSend);

                await this.sendData(formattedSubmission);
                const { requestId } = this.$store.state.page.request;

                if (this.flags.attachments) {
                    await this.$refs.uploadSection.uploadStagedAttachments();
                }

                this.$router.push(`/request/${requestId}`);
            }
            this.$store.dispatch('updateLoading', { key: 'request_submit', status: false });
        },
        async sendFix() {
            // update end time if found faulty
            await this.handleSaveAndContinue(true);
            const toSend = this.validationResults;
            const formattedSubmission = this.getRequestToSend(toSend);
            await this.sendData({ ...formattedSubmission, ...{ requestId: this.data.requestId } });
        },
        getRequestToSend(data) {
            let subjectList = [];
            const {
                timeEnd,
                timeStart,
                date,
                classSize: classSizeString,
                primarySubject,
                subjects,
                ...rest
            } = data;

            const { startDateTime, endDateTime } = this.convertSelectionsToEpochs(
                date,
                timeStart,
                timeEnd,
            );

            const classSize = parseInt(classSizeString, 10);
            subjectList.push(primarySubject);
            subjectList = subjectList.concat(subjects);

            return {
                ...rest,
                startDateTime,
                endDateTime,
                classSize,
                primarySubject,
                subjects: subjectList,
            };
        },
        async sendData(data) {
            let payload = await this.createPayload(data);

            if (this.isEdit) {
                payload = await this.createPayload({ ...{ requestId: this.data.requestId }, ...data });
                return this.$store.dispatch('updateRequest', payload);
            }

            return this.$store.dispatch('createRequest', payload);
        },
        leadingZero(int) {
            if (int < 10) {
                return `0${int}`;
            }
            return int.toString();
        },
        trailingZero(int) {
            if (int === 0) {
                return `${int}0`;
            }
            return int.toString();
        },
        createTimeValue(hours, minutes) {
            return `${this.leadingZero(hours)}:${this.trailingZero(minutes)}`;
        },
        createTimeIncrements(start = 6, end = 18, increment = 15) {
            let t = start * 60;
            const endInMinutes = end * 60;

            const times = [];
            for (let i = 0; t <= endInMinutes; i += 1) {
                const hours = Math.floor(t / 60);
                const minutes = t % 60;

                times.push({
                    value: this.createTimeValue(hours, minutes),
                    text: formatTime(null, hours, minutes, true),
                });
                t += increment;
            }

            return times;
        },
        // the form fields are split into date and then separate time inputs
        // this function gets those from the stored epochs
        epochsToTimeAndDate(startEpoch, endEpoch) {
            const startDate = new Date(startEpoch * 1000);
            const endDate = new Date(endEpoch * 1000);

            const startHours = startDate.getHours();
            const startMinutes = startDate.getMinutes();

            const timeStart = this.createTimeValue(startHours, startMinutes);

            const endHours = endDate.getHours();
            const endMinutes = endDate.getMinutes();

            const timeEnd = this.createTimeValue(endHours, endMinutes);

            return {
                date: startDate,
                timeStart,
                timeEnd,
            };
        },
        populateRequestForm() {
            let { data } = this;

            if (!data) {
                return;
            }
            const {
                startDateTime,
                endDateTime,
                subjects,
                primarySubject,
            } = data;

            if (startDateTime && endDateTime) {
                const { date, timeStart } = this.epochsToTimeAndDate(startDateTime, endDateTime);
                const { timeEnd } = this.epochsToTimeAndDate(startDateTime, endDateTime);
                data = { ...data, ...{ date, timeStart, timeEnd } };
            }

            if (subjects && primarySubject) {
                const primaryIdx = subjects.indexOf(primarySubject.toLowerCase().trim());
                subjects.splice(primaryIdx, 1);
            }

            this.populateFields([], data);
        },
        async handleSaveDraft() {
            await this.handleSaveAndContinue(true);

            // bail if validation didn't pass
            if (this.fieldsWithErrors && this.fieldsWithErrors.length > 0) {
                return;
            }

            this.$store.dispatch('updateLoading', { key: 'request_save_draft', status: true });
            const toSend = this.validationResults;
            toSend.status = draft;
            const formattedSubmission = this.getRequestToSend(toSend);

            const payload = await this.createPayload(formattedSubmission);
            await this.$store.dispatch('createRequest', payload);
            const { requestId } = this.$store.state.page.request;
            this.$store.dispatch('updateLoading', { key: 'request_save_draft', status: false });

            this.$router.push(`/request/${requestId}`);
        },
    },
    mounted() {
        this.scrollToTop();
        this.additionalSubjectOptions = this.tagOptions;

        if (this.isEdit) {
            this.populateRequestForm();
            this.populateEndOptions();
        }
    },
    watch: {
        data(updated) {
            if (this.isEdit) {
                this.populateRequestForm(updated);
                this.populateEndOptions();
            }
        },
    },
};
</script>

<style lang="stylus">
.request-form {
    &__action-row, &__actions {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: flex;
            justify-content: flex-end;
        }
    }
    &__action-row {
        margin-block-start: $nebula-space-2x;
    }

    &__actions {
        margin-block-start: $nebula-space-4x;
        gap: $nebula-space-2x;
    }

    &__datepicker-label {
        prepop-label();
    }
}
</style>
