<template>
    <NebulaGridRow type="flex" class="applicant-empty-state">
        <NebulaColumn :columns="4" :mdSize="4" :smSize="4" :offset="2" :mdOffset="0" :smOffset="0">
            <img
                :src="discoImage"
                class="applicant-empty-state__character-image"
                :alt="$t('Disco character searching')"
            >
        </NebulaColumn>
        <NebulaColumn class="applicant-empty-state__text" :columns="4" :mdSize="4" :smSize="4">
            <h2>{{ $t('applicants-none-header', { ns: 'requestdetails' }) }}</h2>
            <p>
                {{ $t('applicants-none-description', { ns: 'requestdetails' }) }}
            </p>
        </NebulaColumn>
    </NebulaGridRow>
</template>

<script>
import { NebulaGrid } from '@discoveryedu/nebula-components';

const discoImage = require('@/assets/img-disco-searching.png');

export default {
    name: 'ApplicantEmptyState',
    components: {
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
    },
    data() {
        return {
            discoImage,
        };
    },
};
</script>

<style lang="stylus">
.applicant-empty-state {
    card-base();
    padding: $nebula-space-5x;

    &__character-image {
        max-width: 100%;
    }

    &__text {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &>h2 {
            margin: 0 0 $nebula-space-half;
        }
        &>p {
            margin: 0;
        }
    }
}
</style>
