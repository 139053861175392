<template>
    <h2 class="banner-title" :class="{'banner-title--heavy': !overline}">
        <span v-if="overline" class="banner-title__overline">
            {{ overline }}:
        </span>
        {{ title }}
    </h2>
</template>

<script>
export default {
    name: 'BannerTitle',
    props: {
        title: String,
        overline: String,
    },
};
</script>

<style lang="stylus">
responsive-title() {
    font-size: $nebula-font-size-mobile-header-level-1;
    @media (min-width: $nebula-breakpoints-tablet-portrait) {
        font-size: $nebula-font-size-header-level-1;
    }
}

responsive-description() {
    font-size: $nebula-font-size-mobile-header-level-6;
    @media (min-width: $nebula-breakpoints-tablet-portrait) {
        font-size: $nebula-font-size-card-title;
    }
}

.banner-title {
    responsive-title();
    color: $nebula-color-white;
    font-weight: 400;
    line-height: 1em;
    margin-block: 0 $nebula-space-4x;

    &__overline {
        display: block;
    }
    &__overline, &--heavy {
        font-weight: 600;
    }
}
</style>
