<template>
    <NebulaGridRow type="flex">
        <NebulaColumn :columns="12">
            <NebulaBreadcrumbs
                :aria-label="$t('breadcrumbs-aria', { ns: 'usermanagement' })"
                :breadcrumbs="breadcrumbs"
                dropdownUseRouterLinks
                showDimText
            />
        </NebulaColumn>
    </NebulaGridRow>
</template>

<script>
import {
    NebulaGrid,
    NebulaBreadcrumbs,
} from '@discoveryedu/nebula-components';

export default {
    name: 'BreadcrumbsRow',
    components: {
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaBreadcrumbs,
    },
    computed: {
        breadcrumbs() {
            const fallbackBreadcrumb = {
                dim_text: '',
                link: '/',
                text: 'Dashboard',
            };

            const storedBreadcrumbs = this.$store.state.app.breadcrumb;
            return storedBreadcrumbs.length > 1
                ? storedBreadcrumbs : [fallbackBreadcrumb].concat(storedBreadcrumbs);
        },
    },
};
</script>
