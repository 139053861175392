<template>
    <div v-if="data" class="request-details-content">
        <div class="request-details-content__summary">
            <div
                class="request-details-content__title-group"
                :class="{ 'request-details-content__title-group--small': small }"
            >
                <h2
                    class="request-details-content__title"
                    :class="{ 'request-details-content__title--small': small }"
                >
                    {{ typeDisplay }}
                </h2>
                <h3
                    class="request-details-content__subtitle"
                    :class="{ 'request-details-content__subtitle--small': small }"
                >
                    {{ request.title }}
                </h3>
            </div>
            <RequestStatus :status="request.status" :requestId="request.requestId" :date="request.startDateTime" />
            <div v-if="showTimeRemaining">
                <p class="request-details-content__time-message">{{ timeMessage }}</p>
            </div>
            <div class="request-details-content__detail-group">
                <h4 class="request-details-content__section-header">Details</h4>
                <DetailBlock :data="request" v-bind="{ vertical }" />
            </div>
            <p class="request-details-content__caption">*All times are reflected in your local time zone.</p>
        </div>
        <div class="request-details-content__actions" v-if="$slots.actions">
            <slot name="actions" />
        </div>
    </div>
</template>

<script>
import RequestStatus from '@/components/shared/tokens/RequestStatus.vue';
import DetailBlock from '@/components/dashboard/DetailBlock.vue';

import { typeOptions } from '@/data/formData';
import timeRemaining from '@/utils/timeRemaining';
import { timestampNow } from '@/data/placeholder/date';

import {
    roles,
} from '@/constants';

export default {
    name: 'RequestDetails',
    components: {
        RequestStatus,
        DetailBlock,
    },
    computed: {
        typeDisplay() {
            const { type } = this.request;

            if (!type) {
                return '';
            }

            const typeObj = typeOptions.find((each) => each.value === type);
            return typeObj.text;
        },
        appMode() {
            return this.$store.state.app.mode;
        },
        isConnector() {
            return this.appMode === roles.EMPLOYEE_ROLE;
        },
        timeMessage() {
            const now = timestampNow();

            if (now > this.request.startDateTime) {
                return `${timeRemaining(this.request.startDateTime)}`;
            }
            return `Starts in ${timeRemaining(this.request.startDateTime)}`;
        },
        showTimeRemaining() {
            const now = timestampNow();
            const threshold = 60 * 60 * 24 * 5;

            const timeDiff = this.request.startDateTime - now;

            const meetsThreshold = timeDiff < threshold;

            if (this.timeRemaining && meetsThreshold) {
                return true;
            }

            return false;
        },
        request() {
            return this.data || this.$store.state.page.request;
        },
    },
    props: {
        data: Object,
        vertical: Boolean,
        small: Boolean,
        timeRemaining: Boolean,
    },
};
</script>

<style lang="stylus">
.request-details-content {
    container-type: inline-size;
    container-name: request-details-content
    width: 100%;

    @media (min-width: $nebula-breakpoints-tablet-portrait) {
        display: flex;
        justify-content: space-between;
    }

    &__caption {
        font-size: $nebula-font-size-caption;
        margin-block-end: 0;
    }

    &__title-group {
        margin-bottom: $nebula-space-3x;
        &--small {
            card-internal-title();
            margin-bottom: $nebula-space-2x;
        }
    }

    &__title {
        margin: 0 0 $nebula-space-half;
        font-size: $nebula-font-size-mobile-header-level-2;
        &--small {
            card-internal-title();
            margin-block-end: $nebula-space-1x;
        }
    }

    &__detail-group {
        margin-block-start: $nebula-space-2x;
    }

    &__subtitle {
        body-1();
        margin: 0;
        font-weight: $nebula-font-weight-body;
        &--small {
            nebula-text-body-2();
        }
    }

    &__section-header {
        font-size: $nebula-font-size-mobile-body-1;
        margin: 0 0 $nebula-space-1x;
    }

    &__actions {
        display: flex;
        gap: $nebula-space-2x;
        flex-direction: column;
        justify-content: flex-end;
        max-width: 500px;
        min-width: 300px;
    }

    &__participant-card {
        align-self: flex-end;
    }

    &__requested-button {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    &__time-message {
        color: $nebula-color-feedback-success-700;
        margin-block: $nebula-space-2x;
    }
}

@container request-details-content (min-width: 500px) {
    .request-details-content__section-header {
        font-size: $nebula-font-size-mobile-header-level-5;
    }
}
</style>
