<template>
    <div class="request-detail-card">
        <RequestDetailsContent v-if="data || request" v-bind="{ data: request }">
            <template v-slot:actions>
                <slot name="actions" />
            </template>
        </RequestDetailsContent>
</div>
</template>

<script>
// import RequestStatus from '@/components/shared/tokens/RequestStatus.vue';
// import DetailBlock from '@/components/dashboard/DetailBlock.vue';
import RequestDetailsContent from '@/components/shared/requests/RequestDetails.vue';

import { typeOptions } from '@/data/formData';
import {
    roles,
    // awaitingConfirmation,
    // actionRequired,
} from '@/constants';
import createPayload from '@/mixins/data/createPayload';

export default {
    name: 'RequestDetailCard',
    components: {
        // RequestStatus,
        // DetailBlock,
        RequestDetailsContent,
    },
    mixins: [createPayload],
    props: {
        data: Object,
    },
    computed: {
        typeDisplay() {
            const { type } = this.request;

            if (!type) {
                return '';
            }

            const typeObj = typeOptions.find((each) => each.value === type);
            return typeObj.text;
        },
        appMode() {
            return this.$store.state.app.mode;
        },
        isConnector() {
            return this.appMode === roles.EMPLOYEE_ROLE;
        },
        request() {
            return this.data || this.$store.state.page.request;
        },
    },
    data() {
        return {
            partnerTitle: '',
            partnerGroup: '',
        };
    },
};
</script>

<style lang="stylus">
.request-detail-card {
    card-base();
    background-color: $nebula-color-platform-interface-100;
    border: 1px solid $nebula-color-platform-interface-400;
    padding: $nebula-space-3x;

    @media (min-width: $nebula-breakpoints-tablet-portrait) {
        display: flex;
        justify-content: space-between;
    }
}
</style>
