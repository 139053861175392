<template>
    <NebulaBlockMessage
        class="careers-block-message"
        icon="warning--filled"
        v-bind="boundProps"
    />
</template>

<script>
import { NebulaBlockMessage } from '@discoveryedu/nebula-components';

export default {
    name: 'ErrorBanner',
    components: {
        NebulaBlockMessage,
    },
    props: {
        header: {
            type: String,
        },
        color: {
            type: String,
        },
        description: {
            type: String,
        },
    },
    computed: {
        boundProps() {
            return {
                header: this.header,
                color: this.color,
                description: this.description,
            };
        },
    },
};
</script>

<style lang="stylus">
.careers-block-message {
    max-width: unset;
}
</style>
