<template>
    <div>
        <div class="banner-carousel">
            <PageButton
                type="prev"
                v-bind="pageButtonAttrs"
                :isDisabled="active <= bannersMin"
                @page-change="handlePageChange"
            />
            <PageButton
                type="next"
                v-bind="pageButtonAttrs"
                @page-change="handlePageChange"
                :isDisabled="active >= bannersMax"
            />
            <div
                v-for="(banner, idx) in banners"
                :key="`banner-${idx}`"
            >
                <SpotlightBanner v-if="active === idx" :data="banner" />
            </div>
        </div>
        <DotsPagination :length="banners.length" :current="active" @dot-clicked="handleDotClick" />
    </div>
</template>

<script>
import PageButton from '@/components/shared/tokens/PageButton.vue';
import SpotlightBanner from '@/components/shared/SpotlightBanner.vue';
import DotsPagination from '@/components/shared/tokens/Banner/DotsPagination.vue';

import { spotlights, bannerOrder } from '@/utils/data/resources';

export default {
    name: 'BannerCarousel',
    components: {
        PageButton,
        SpotlightBanner,
        DotsPagination,
    },
    data() {
        return {
            bannersMin: 0,
            active: 0,
        };
    },
    computed: {
        bannerOrder() {
            return bannerOrder();
        },
        banners() {
            return this.bannerOrder.filter((key) => !!spotlights[key].banner && spotlights[key].banner.active)
                .map((key) => {
                    const spotlight = spotlights[key];
                    if (!spotlight) {
                        return null;
                    }

                    const { banner, type, id } = spotlight;
                    return {
                        ...banner,
                        ...{
                            key,
                            type,
                            id,
                        },
                    };
                });
        },
        pageButtonAttrs() {
            return {
                class: 'banner-carousel__page-button',
                min: 0,
                max: this.bannersMax,
            };
        },
        bannersMax() {
            if (!this.banners) {
                return 0;
            }
            return this.banners.length - 1;
        },
    },
    methods: {
        handleDotClick(idx) {
            this.active = idx;
        },
        handlePageChange(type) {
            if (type === 'next') {
                if (this.active >= this.bannersMax) {
                    return;
                }
                this.active += 1;
            }
            if (type === 'prev') {
                if (this.active <= this.bannersMin) {
                    return;
                }
                this.active -= 1;
            }
        },
    },
};
</script>

<style lang="stylus">
.banner-carousel {
    --nebula-card-carousel-controls-background-color: $nebula-color-platform-interactive-800;
    position: relative;
    &__page-button {
        opacity: .8;
        z-index: 10;
    }
}
</style>
