<template>
    <div>
        <NebulaGridRow v-if="showUploader && flags.attachments" type="flex">
            <NebulaColumn :columns="12">
                <PageSection>
                    <SubSection :header="header">
                        <NebulaGridRow>
                            <NebulaGridBlock :columns="12">
                                <Uploader
                                    v-bind="{ newMode }"
                                    ref="uploader"
                                    :requestId="requestId"
                                    @upload-update="updateAttachment"
                                    @refresh-attachments="getAttachments"
                                    @stage-attachment="stageAttachment"
                                />
                                <NebulaFormHelper
                                    helperId="string"
                                    text="You can upload any relevant files here (i.e. topics to cover,
                                        classrooms rules and expectations, suggested agenda). This will
                                        be included as part of your request and viewable by the applicant."
                                />
                            </NebulaGridBlock>
                        </NebulaGridRow>
                    </SubSection>
                </PageSection>
            </NebulaColumn>
        </NebulaGridRow>
        <NebulaGridRow type="flex">
            <NebulaColumn :columns="12">
                <AttachmentsSection
                    :uploadsLoading="loading.attachments"
                    v-bind="{ formVersion }"
                    @unstage-attachment="unstageAttachment"
                />
            </NebulaColumn>
        </NebulaGridRow>
    </div>
</template>

<script>
import { NebulaGrid, NebulaFormHelper } from '@discoveryedu/nebula-components';
import AttachmentsSection from '@/components/shared/requests/AttachmentsSection.vue';
import Uploader from '@/components/resources/Uploader.vue';
import SubSection from '@/components/shared/layout/SubSection.vue';
import PageSection from '@/components/shared/layout/PageSection.vue';

import createPayload from '@/mixins/data/createPayload';

import { roles, scheduled } from '@/constants';

export default {
    name: 'UploadSection',
    mixins: [createPayload],
    components: {
        NebulaFormHelper,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaColumn: NebulaGrid.NebulaColumn,
        PageSection,
        AttachmentsSection,
        SubSection,
        Uploader,
    },
    props: {
        requestId: String,
        requestOwnerId: String,
        formVersion: {
            type: Boolean,
            default: false,
        },
        newMode: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        flags() {
            return this.$store.getters.flags;
        },
        request() {
            return this.$store.getters.request;
        },
        showUploader() {
            if (this.newMode && this.$store.getters.appMode === roles.EDUCATOR_ROLE) {
                return true;
            }
            const { userId } = this.$store.getters;
            const isOwner = userId === this.requestOwnerId;
            let isAssociated;
            const isScheduled = this.request.status === scheduled;
            if (this.$store.getters.appMode === roles.EMPLOYEE_ROLE) {
                isAssociated = this.request.employeeId === userId;
            }
            return (isAssociated && isScheduled) || isOwner;
        },
        header() {
            return this.formVersion ? 'Upload resources (optional)' : 'Upload resources';
        },
    },
    data() {
        return {
            attachments: [],
            loading: {
                attachments: true,
            },
        };
    },
    methods: {
        updateAttachment(payload) {
            this.$store.dispatch('updateRequestAttachment', payload);
        },
        stageAttachment(payload) {
            this.$store.dispatch('updateRequestAttachment', payload);
        },
        unstageAttachment(filename) {
            this.$refs.uploader.unstage(filename);
        },
        async uploadStagedAttachments() {
            return this.$refs.uploader.uploadStagedAttachments();
        },
        async getAttachments() {
            const payload = await this.createPayload({ requestId: this.requestId });
            await this.$store.dispatch('getRequestAttachments', payload);

            return null;
        },
    },
    async mounted() {
        if (this.flags.attachments && !this.newMode) {
            await this.getAttachments();
        }
        this.loading.attachments = false;
    },
};
</script>
