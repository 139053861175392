import {
    actionRequired,
    completed,
    scheduled,
    canceled,
    pendingMatches,
    openStatuses,
    awaitingConfirmation,
    allHappyStatuses,
    roles,
} from '@/constants';

import {
    timestampNow,
    inXdays,
    thisWeek,
    thisMonth,
    thisYear,
} from './placeholder/date';

const defaultRanges = {
    week: thisWeek(),
    month: thisMonth(),
    year: thisYear(),
};

const educatorTabMap = [
    {
        value: 'week',
        name: 'This Week',
        level: 2,
        filters: {
            status: allHappyStatuses,
            startDate: [defaultRanges.week.startDate],
            endDate: [defaultRanges.week.endDate],
        },
    },
    {
        value: 'month',
        name: 'This Month',
        level: 2,
        filters: {
            status: allHappyStatuses,
            startDate: [defaultRanges.month.startDate],
            endDate: [defaultRanges.month.endDate],
        },
    },
    {
        value: actionRequired,
        name: 'Action Required',
        level: 2,
        filters: {
            startDate: [timestampNow()],
            endDate: [inXdays(365)],
            status: [actionRequired],
        },
    },
    {
        value: completed,
        name: 'Completed',
        level: 2,
        filters: {
            startDate: [inXdays(-365)],
            endDate: [timestampNow()],
            status: [completed],
        },
    },
];

// map category request tabs to appropriate action
const connectorTabMap = [
    {
        value: 'open-requests',
        name: 'Open Requests',
        level: 1,
        filters: {
            employee_status: ['open'],
            startDate: [timestampNow()],
            endDate: [inXdays(365)],
        },
    },
    {
        value: 'my-connects',
        name: 'My Connects',
        level: 1,
        filters: {
            employee_status: ['assigned', 'pending', 'direct'],
            status: [scheduled, awaitingConfirmation, actionRequired, pendingMatches],
            startDate: [inXdays(-365)],
            endDate: [inXdays(365)],
        },
    },
    {
        value: 'week',
        name: 'This Week',
        level: 2,
        filters: {
            startDate: [defaultRanges.week.startDate],
            endDate: [defaultRanges.week.endDate],
        },
    },
    {
        value: 'month',
        name: 'This Month',
        level: 2,
        filters: {
            startDate: [defaultRanges.month.startDate],
            endDate: [defaultRanges.month.endDate],
        },
    },
    {
        value: actionRequired,
        name: 'Action Required',
        level: 2,
        mode: 'self',
        filters: {
            startDate: [timestampNow()],
            endDate: [inXdays(90)],
            status: [awaitingConfirmation],
            employee_status: ['direct'],
        },
    },
    {
        value: completed,
        name: 'Completed',
        level: 2,
        mode: 'self',
        filters: {
            startDate: [inXdays(-365)],
            endDate: [timestampNow()],
            status: [completed],
            employee_status: ['assigned'],
        },
    },
];

// map category request tabs to appropriate action
const orgAdminTabMap = [
    {
        value: 'open-requests',
        name: 'Open Requests',
        level: 1,
        filters: {
            status: openStatuses,
        },
    },
    {
        value: 'my-organization-connects',
        name: "My Organization's Connects",
        level: 1,
        filters: {
            status: [scheduled, completed, canceled],
        },
    },
    {
        value: 'week',
        name: 'This Week',
        level: 2,
        filters: {
            startDate: [defaultRanges.week.startDate],
            endDate: [defaultRanges.week.endDate],
        },
    },
    {
        value: 'month',
        name: 'This Month',
        level: 2,
        filters: {
            startDate: [defaultRanges.month.startDate],
            endDate: [defaultRanges.month.endDate],
        },
    },
    {
        value: 'year',
        name: 'This Year',
        level: 2,
        filters: {
            startDate: [defaultRanges.year.startDate],
            endDate: [defaultRanges.year.endDate],
        },
    },
];

const sort = {
    default: {
        value: 'upcoming',
        label: 'Upcoming (default)',
    },
    connector: [
        {
            value: 'connector-ascending',
            label: 'Connector (A to Z)',
        },
        {
            value: 'connector-descending',
            label: 'Connector (Z to A)',
        },
    ],
    type: [
        {
            value: 'type-ascending',
            label: 'Type (A to Z)',
        },
        {
            value: 'type-descending',
            label: 'Type (Z to A)',
        },
    ],
    subject: [
        {
            value: 'subject-ascending',
            label: 'Subject Area (A to Z)',
        },
        {
            value: 'subject-descending',
            label: 'Subject Area (Z to A)',
        },
    ],
};

export default {
    [roles.EDUCATOR_ROLE]: educatorTabMap,
    [roles.EMPLOYEE_ROLE]: connectorTabMap,
    [roles.ORGADMIN_ROLE]: orgAdminTabMap,
    sort,
};
