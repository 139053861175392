<template>
    <PageSection>
        <SubSection header="Attached Resources">
            <NebulaGridRow gutter class="resources-section__content">
                <NebulaGridBlock v-bind="gridProps" v-for="(resource, idx) in evergreenResources" :key="`evergreen-resource-${idx}`">
                    <GrayBoxLoader v-if="loading.evergreen" class="resources-section__attachment-loader" />
                    <AttachmentCard v-else v-bind="{ resource }" />
                </NebulaGridBlock>
                <NebulaGridBlock v-if="flags.attachments && uploadsLoading" v-bind="gridProps">
                    <GrayBoxLoader class="resources-section__attachment-loader" />
                </NebulaGridBlock>
                <NebulaGridBlock v-else-if="flags.attachments" v-bind="gridProps" v-for="(resource, idx) in resources" :key="`resource-${idx}`">
                    <AttachmentCard v-bind="{ resource }" @delete-attachment="deleteAttachment" @unstage-attachment="unstageAttachment" />
                </NebulaGridBlock>
            </NebulaGridRow>
        </SubSection>
    </PageSection>
</template>

<script>
import {
    NebulaGrid,
} from '@discoveryedu/nebula-components';
import SubSection from '@/components/shared/layout/SubSection.vue';
import PageSection from '@/components/shared/layout/PageSection.vue';
import AttachmentCard from '@/components/resources/AttachmentCard.vue';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';

import { fetchAssetFromDE } from '@/utils/fetches';
import createPayload from '@/mixins/data/createPayload';

export default {
    name: 'AttachmentsSection',
    components: {
        AttachmentCard,
        GrayBoxLoader,
        PageSection,
        SubSection,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
    },
    mixins: [createPayload],
    props: {
        uploadsLoading: Boolean,
        formVersion: Boolean,
    },
    data() {
        return {
            evergreenResources: [
            ],
            resources: [
            ],
            loading: {
                evergreen: true,
            },
        };
    },
    computed: {
        uploadedAttachments() {
            return this.$store.getters.requestAttachments;
        },
        flags() {
            return this.$store.getters.flags;
        },
        requestId() {
            return this.$store.getters.request.requestId;
        },
        gridProps() {
            const fullWidthProps = {
                columns: 6,
                smSize: 4,
            };

            const formProps = {
                columns: 12,
            };

            return this.formVersion ? formProps : fullWidthProps;
        },
    },
    methods: {
        async getResource(assetId) {
            const payload = await this.createPayload();
            const response = await fetchAssetFromDE(payload.token, assetId);

            if (!response.data && !response.data.asset) {
                return;
            }

            const {
                asset: {
                    id, title, is_downloadable: isDownloadable, media_files: [{ url: downloadUrl, file_size: fileSize, file_name: fileName }],
                },
            } = response.data;

            const type = this.getFileType(fileName);

            const parsed = {
                id,
                title,
                isDownloadable,
                downloadUrl,
                fileSize,
                type,
                evergreen: true,
            };

            this.evergreenResources.push(parsed);
        },
        parseUploadedResources() {
            const { requestAttachments: attachments = [] } = this.$store.getters;
            this.resources = [];

            attachments.forEach((attachment) => {
                const {
                    url: downloadUrl,
                    filename: title,
                    is_owner: isOwner,
                    size: fileSize,
                    status,
                } = attachment;

                const parsed = {
                    status,
                    downloadUrl,
                    title,
                    isOwner,
                    isDownloadable: Boolean(downloadUrl),
                    fileSize: this.getFileSize(fileSize),
                    type: this.getFileType(title),
                    evergreen: false,
                };

                this.resources.push(parsed);
            });
        },
        getFileType(fileName) {
            const regex = /(?:[.\S]+)\.(?<extension>[a-z|A-Z]+)$/;
            const match = fileName.match(regex);
            let type;

            if (match) {
                const { extension } = match.groups;
                type = extension.toUpperCase();
            }

            return type;
        },
        getFileSize(bytes) {
            if (bytes >= 1000000) {
                const mb = parseFloat((bytes / 1000000).toFixed(1));
                return `${mb}MB`;
            }
            const kb = Math.ceil(parseFloat(bytes / 1000));
            return `${kb}KB`;
        },
        async deleteAttachment(filename) {
            const payload = await this.createPayload({ filename, requestId: this.requestId });
            await this.$store.dispatch('deleteRequestAttachment', payload);

            this.parseUploadedResources();
        },
        async unstageAttachment(file) {
            this.$emit('unstage-attachment', file);
        },
    },
    async mounted() {
        const allResourceAssets = ['77c4972a-6dca-4cc9-8121-b33d77cb30f4'];

        allResourceAssets.forEach(async (assetId) => {
            await this.getResource(assetId);
        });
        this.loading.evergreen = false;

        this.parseUploadedResources();
    },
    watch: {
        uploadedAttachments(updated, prev) {
            if (!updated) {
                return;
            }
            if (updated.length > 0 || (prev && prev.length > 0)) {
                this.parseUploadedResources();
            }
        },
    },
};
</script>

<style lang="stylus">
.resources-section {
    &__content {
        margin-block-start: $nebula-space-2x;
    }

    &__attachment-loader {
        height: 80px;
        margin-block-start: $nebula-space-2x;
    }
}
</style>
