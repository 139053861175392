<template>
    <div
        class="uploader__drop-area"
        @drop="(e) => handleDrop(e)"
        @dragover="(e) => handleDragover(e)"
    >
        <form>
            <input
                ref="uploaderInput"
                class="uploader__input"
                type="file"
                id="fileElem"
                multiple
                accept="image/png,image/jpeg,application/pdf,application/vnd.openxmlformats-officedocument.presentationml.presentation"
                @change="fileInputHandler($event)"
            >
        </form>
        <div class="uploader__content">
            <NebulaIcon
                class="uploader__icon"
                symbol-id="upload"
                size="m"
            />
            <p class="uploader__text uploader__instructions">
                <button class="uploader__link" ref="uploaderLink" @click="handleClick">Upload a file</button>
                <span> or drag and drop</span>
            </p>
            <p class="uploader__text">
                PDF, JPG, PNG, PPTX up to 10MB
            </p>
        </div>
    </div>
</template>

<script>
import { NebulaIcon } from '@discoveryedu/nebula-components';

import createPayload from '@/mixins/data/createPayload';
import scrollToTop from '@/mixins/scrollToTop';

// in bytes
const fileSizeLimit = 1024 * 1024 * 10;

export default {
    name: 'Uploader',
    components: {
        NebulaIcon,
    },
    mixins: [createPayload, scrollToTop],
    props: {
        requestId: String,
        newMode: Boolean,
    },
    data() {
        return {
            staged: [],
        };
    },
    methods: {
        unstage(filename) {
            const index = this.staged.findIndex((each) => each.name === filename);
            this.$store.dispatch('updateRequestAttachment', { file: this.staged[index], status: 'unstaged' });
            this.staged.splice(index, 1);
        },
        async fileInputHandler(e) {
            this.$store.dispatch('updatePage', { error: null });

            const { files } = e.target;
            const filesArr = [...Array(files.length - 0).keys()].map((idx) => files[idx]);
            const passes = !filesArr.find((file) => !this.validateFile(file));

            if (this.newMode && passes) {
                filesArr.forEach((rawFile) => {
                    const file = this.cleanFileName(rawFile);
                    this.staged.push(file);
                    this.$emit('stage-attachment', { file, status: 'staged' });
                });
                return;
            }

            if (passes) {
                await this.upload(files);
            }
        },
        handleClick() {
            this.$refs.uploaderInput.click();
        },
        async handleDrop(ev) {
            this.$store.dispatch('updatePage', { error: null });

            ev.preventDefault();

            const filesArr = [...ev.dataTransfer.items].map((item) => item.getAsFile());

            const passes = !filesArr.find((file) => !this.validateFile(file));

            if (this.newMode && passes) {
                filesArr.forEach((rawFile) => {
                    const file = this.cleanFileName(rawFile);
                    this.staged.push(file);
                    this.$emit('stage-attachment', { file, status: 'staged' });
                });
                return;
            }

            if (passes) {
                await this.upload(filesArr);
            }
        },
        handleDragover(ev) {
            ev.preventDefault(ev);
        },
        cleanFileName(rawFile) {
            return new File([rawFile], rawFile.name.replace(/[^a-zA-Z0-9-_.]/g, '_'));
        },
        async uploadSingle(rawFile) {
            const file = this.cleanFileName(rawFile);
            this.$emit('upload-update', { file, status: 'uploading' });

            const payload = await this.createPayload({ requestId: this.requestId, file });

            const presigned = await this.$store.dispatch('getUploadUrl', payload);

            if (presigned === 'error') {
                this.$store.dispatch('updateRequestAttachment', { file, status: 'error' });
                return;
            }

            const uploadPayload = await this.createPayload({ presigned: presigned.data.presignedUrl, file });

            await this.$store.dispatch('uploadResource', uploadPayload);
        },
        async uploadStagedAttachments() {
            return this.upload(this.staged, true);
        },
        async upload(files, staged = false) {
            const { requestAttachments: currentAttachments = [] } = this.$store.getters;
            const filesArr = [...files];
            if (filesArr.length < 1) {
                return;
            }

            if ((currentAttachments.length + filesArr.length) > 10) {
                const description = 'Limit of 10 attachments per request';
                this.$store.dispatch('updatePage', { error: { description } });
                this.scrollToTop(true);
                return;
            }

            await Promise.all(filesArr.map((file) => this.uploadSingle(file)));

            if (staged) {
                return;
            }

            setTimeout(() => {
                this.$emit('refresh-attachments');
            }, 5000);
        },
        validateFile(file) {
            const { size, type, name } = file;
            const supportedTypes = [
                'image/png',
                'image/jpeg',
                'application/pdf',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            ];

            if (size > fileSizeLimit) {
                const description = `${name} is over the file size limit of 10MB`;
                this.$store.dispatch('updatePage', { error: { description } });
                this.scrollToTop(true);
                return false;
            }

            if (!supportedTypes.includes(type)) {
                const description = `${name} is not a supported file type`;
                this.$store.dispatch('updatePage', { error: { description } });
                this.scrollToTop(true);
                return false;
            }

            return true;
        },
    },
    beforeDestroy() {
        this.$store.dispatch('updatePage', { error: null });
    },
};
</script>

<style lang="stylus">
.uploader {
    &__drop-area {
        --clr-border: $nebula-color-platform-interactive-800;
        align-items: center;
        background-image:
            repeating-linear-gradient(0deg, var(--clr-border), var(--clr-border) 6px, transparent 6px, transparent 12px),
            repeating-linear-gradient(90deg, var(--clr-border), var(--clr-border) 6px, transparent 6px, transparent 12px),
            repeating-linear-gradient(180deg, var(--clr-border), var(--clr-border) 6px, transparent 6px, transparent 12px),
            repeating-linear-gradient(270deg, var(--clr-border), var(--clr-border) 6px, transparent 6px, transparent 12px)
        background-position:  0 0, 0 0, 100% 0, 0 100%;
        background-repeat: no-repeat;
        background-size: 2px 100%, 100% 2px, 2px 100% , 100% 2px;
        display: flex;
        border-radius: 2px;
        justify-content: center;
        line-height: 1;
        padding: $nebula-space-4x + $nebula-space-half;
        width: 100%;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: $nebula-space-1x;
        justify-content: center;
    }

    &__input {
        display: none;
    }

    &__text {
        font-size: $nebula-font-size-caption;
        text-align: center;
        margin: 0;
    }

    &__instructions {
        font-weight: 600;
    }

    &__link {
        border: none;
        link();
        background: none;
        display: inline;
        padding: 0;
    }

    &__error {
        color: $nebula-color-feedback-error-400;
    }
}
</style>
