export default {
    // to use mixin add a ref to the element to be controlled, and
    // set the value of heightRef to that ref string
    data() {
        return {
            maxHeight: null,
        };
    },
    mounted() {
        this.setContainerHeight(this.heightRef);
        this.boundedResize = this.setContainerHeight.bind(this);
        window.addEventListener('resize', this.boundedResize);
    },
    destroyed() {
        window.removeEventListener('resize', this.boundedResize);
    },
    methods: {
        setContainerHeight(refName) {
            const productContainerEl = document.querySelector('.product-container');
            const paddingBottom = parseInt(window.getComputedStyle(productContainerEl).getPropertyValue('padding-bottom'), 10);
            const offset = productContainerEl.querySelector('.page-wrapper').offsetTop + paddingBottom;
            this.maxHeight = window.innerHeight - offset > 400 ? `${window.innerHeight - offset}px` : '400px';

            if (this.$refs[refName]) {
                const el = this.$refs[refName].$el;
                el.style.maxHeight = this.maxHeight;
            }
        },
    },
};
