<template>
    <div class="message-data-row">{{ dateString }}</div>
</template>

<script>
export default {
    name: 'MessageDataRow',
    props: {
        dateString: String,
    },
};
</script>

<style lang="stylus">
.message-data-row {
    nebula-text-caption();
    font-weight: 600;
    display: flex;
    align-items: center;
    color: $nebula-color-platform-neutral-700;
    gap: $nebula-space-1x;
    width: 100%;

    &:before {
        content: '';
        border-bottom: 1px solid $nebula-color-platform-neutral-400;
        height: 0;
        width: 10px;
        flex: 1;
    }
    &:after {
        content: '';
        border-bottom: 1px solid $nebula-color-platform-neutral-400;
        height: 0;
        width: 10px;
        flex: 1;
    }
}
</style>
