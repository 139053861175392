<template>
    <button
        class="messages__select"
        :class="{'messages__select--active': active }"
        @click="clickHandler(requestId)"
    >
        <div class="messages__unread-indicator" />
        <ProfileAvatar
            :image="partner.avatarUrl"
            :border="false"
            :name="partner.displayName"
            size="m"
        />
        <div>
            <h3 class="messages__request-title">{{ title }}</h3>
            <p class="messages__message-partner-name">{{ partner.displayName }}</p>
            <p class="messages__message-partner-group">{{ partner.group }}</p>
        </div>
    </button>
</template>

<script>
import ProfileAvatar from '@/components/shared/ProfileAvatar.vue';

export default {
    name: 'MessageSelectButton',
    components: {
        ProfileAvatar,
    },
    props: {
        partner: Object,
        title: String,
        active: Boolean,
        clickHandler: Function,
        requestId: String,
    },
    mounted() {
        if (this.active) {
            const button = document.querySelector('.messages__select--active');
            const parent = button.parentNode;

            const { top } = button.getBoundingClientRect();
            const { top: parentTop } = parent.getBoundingClientRect();

            parent.scrollTo({
                behavior: 'smooth',
                left: 0,
                top: top - parentTop,
            });
        }
    },
};
</script>
