<template>
    <EducatorRequestDetails
        ref="educatorRequestDetails"
        v-if="educatorRoles.includes(appMode) && requestId"
    />
    <EmployeeRequestDetails
        ref="partnerRequestDetails"
        v-else-if="partnerRoles.includes(appMode) && requestId"
    />
</template>

<script>
import EducatorRequestDetails from '@/views/educators/RequestDetails.vue';
import EmployeeRequestDetails from '@/views/employees/RequestDetails.vue';

import createPayload from '@/mixins/data/createPayload';

import {
    partnerRoles,
    educatorRoles,
} from '@/constants';

export default {
    name: 'RequestDetails',
    components: {
        EducatorRequestDetails,
        EmployeeRequestDetails,
    },
    mixins: [createPayload],
    computed: {
        appMode() {
            return this.$store.state.app.mode;
        },
        requestId() {
            return this.$route.params.id;
        },
        request() {
            return this.$store.getters.request;
        },
    },
    data() {
        return {
            confirmation: null,
            filteredApplicants: [],
            requestedEmployeeId: null,
            partnerRoles,
            educatorRoles,
        };
    },
    methods: {
        async getSingleRequest() {
            const payload = await this.createPayload({ requestId: this.requestId });
            await this.$store.dispatch('getRequests', payload);

            this.loading.request = false;
            return null;
        },
    },
    watch: {
        async requestId() {
            if (this.requestId !== this.request.requestId) {
                const ref = educatorRoles.includes(this.appMode) ? 'educatorRequestDetails' : 'partnerRequestDetails';
                this.$refs[ref].update();
            }
        },
    },
};
</script>

<style lang="stylus">
.request-details {
    &__block-message {
        width: 100%;
        max-width: 100%;
        margin-block-start: $nebula-space-2x;
    }

    &__detail-section {
        --nebula-icon-button-padding: 0;
        .nebula-icon-button--l {
            --nebula-icon-button-padding: 0;
            --nebula-icon-button-background-hover: transparent;
            --nebula-icon-button-fill: $nebula-color-platform-interface-1000;
            --nebula-icon-button-fill-hover: $nebula-color-platform-interactive-800;
            .nebula-icon-button__icon {
                height: 24px;
                width: 24px;
            }
        }
    }

    &__copy-group {
        display: flex;
    }

    &__copy-button {
        margin: 0 $nebula-space-2x 0 0;
    }

    &__card-action-button {
        flex: 1;
        display: flex;
        justify-content: center;
    }

    &__copy-confirmation {
        font-size: $nebula-font-size-caption;
        margin-inline-start: $nebula-space-2x;
        margin-block: 0;
    }

    &__meeting-link {
        margin: 0;
        link();
    }

    &__request-button {
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
</style>
