<template>
    <section class="spotlight-banner" :style="{backgroundImage: `url(${backgroundImage}`}">
        <div class="spotlight-banner__content">
            <BannerTitle :title="data.title" :overline="data.overline" />
            <BannerDescription :description="data.description" />
            <NebulaButton
                v-for="(button, idx) in data.buttons"
                :link="viewAllLink"
                :key="idx"
                :text="button.display"
                routerLink
            />
        </div>
        <div class="spotlight-banner__logo-container" v-if="bannerLogo">
            <img :src="bannerLogo" :alt="data.logoAltText">
        </div>
    </section>
</template>

<script>
import { NebulaButton } from '@discoveryedu/nebula-components';
import BannerTitle from '@/components/shared/tokens/Banner/BannerTitle.vue';
import BannerDescription from '@/components/shared/tokens/Banner/BannerDescription.vue';

export default {
    name: 'SpotlightBanner',
    components: {
        BannerTitle,
        BannerDescription,
        NebulaButton,
    },
    props: {
        data: Object,
    },
    data() {
        return {
            backgroundImage: null,
            bannerLogo: null,
        };
    },
    computed: {
        spotlight() {
            return this.$store.state.app.spotlight;
        },
        viewAllLink() {
            if (this.data.type === 'general') {
                return '/content';
            }
            const pathBase = this.data.type;
            return `${pathBase}/${this.data.id}`;
        },
    },
    mounted() {
        const { key: spotlightKey } = this.data;
        if (spotlightKey) {
            const screenWidth = window.innerWidth;
            let imgSuffix = '';

            if (screenWidth < 1200) {
                imgSuffix = '_sm';
            } else if (screenWidth < 2400) {
                imgSuffix = '_md';
            }
            // eslint-disable-next-line global-require, import/no-dynamic-require
            this.backgroundImage = require(`@/assets/category/${spotlightKey}/${spotlightKey}_banner-background${imgSuffix}.jpg`);
            if (this.data.logoAltText) {
                // eslint-disable-next-line global-require, import/no-dynamic-require
                this.bannerLogo = require(`@/assets/category/${spotlightKey}/${spotlightKey}_banner-logo.svg`);
            }
        }
    },
};
</script>

<style lang="stylus">
$mobile-image-height = 150px;

.spotlight-banner {
    background-position: left $mobile-image-height + 50px;
    background-repeat: no-repeat;
    background-size: 100%;

    @media (min-width: 600px) {
        background-size: contain;
    }

    @media (min-width: $nebula-breakpoints-tablet-portrait) {
        // gradient-overlay();
        background-position: right top;
        background-size: cover;
        display: flex;
        min-height: 466px;
    }

    @media (min-width: $nebula-breakpoints-tablet-landscape) {
        background-position: right top;
    }

    @media (min-width: $nebula-breakpoints-desktop) {
        background-size: 70%;
    }

    &__content {
        gradient-overlay-vertical();
        padding-inline-start: $nebula-space-7x;
        padding-block: $nebula-space-5x;
        padding-block-end: 150px;
        position: relative;
        z-index: 5;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            gradient-overlay();
            padding-inline-start: $nebula-space-9x;
            padding-block-end: $nebula-space-5x;
            width: 45%;
        }
    }

    &__overline {
        display: block;
    }

    &__title, &__description {
        color: $nebula-color-white;
    }

    &__title, &__overline {
        responsive-title();
        line-height: 1em;
    }

    &__title {
        font-weight: 400;
        margin-block: 0 $nebula-space-4x;
    }

    &__overline {
        font-weight: 600;
    }

    &__logo-container {
        position: absolute;
        bottom: $nebula-space-2x;
        right: 0;
        padding-inline-end: $nebula-space-4x;
        margin-block-start: $nebula-space-3x;
        z-index: 5;

        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            bottom: inherit;
            right: inherit;
            position: relative;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            flex: 1;
            margin-block-start: 0;
            padding-inline-end: $nebula-space-7x;
            padding-block-end: $nebula-space-5x;
        }

        img {
            width: $mobile-image-height;
            @media (min-width: $nebula-breakpoints-tablet-portrait) {
                width: 270px;
            }
        }
    }
}
</style>
