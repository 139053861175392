const viewModeButtons = [
    {
        size: 's',
        text: 'Grid',
    },
    {
        active: true,
        size: 's',
        text: 'List',
    },
];

export default viewModeButtons;
