<template>
    <NebulaPageWrapper padded contained>
        <NebulaGridRow v-if="data.banner">
            <NebulaGridBlock :columns="12">
                <ContentHeroBanner v-if="data.title" :data="data" :category="spotlightKey" />
            </NebulaGridBlock>
        </NebulaGridRow>
        <NebulaGridRow v-if="isSpotlight">
            <NebulaGridBlock :columns="12">
                <h1 class="category-content__spotlight-title">Spotlight on {{ data.title }}</h1>
            </NebulaGridBlock>
        </NebulaGridRow>
        <OrgResourcesRow
            v-for="(subcategory, idx) in subcategories"
            :key="`subcategory-${idx}`"
            :title="subcategory.name"
            :category="spotlightKey"
            :subcategory="subcategory.key"
            :viewAll="{ show: true, link: `${subcategory.key}`}"
        />
    </NebulaPageWrapper>
</template>

<script>
import { NebulaGrid } from '@discoveryedu/nebula-components';

import OrgResourcesRow from '@/components/Profile/shared/OrgResourcesRow.vue';
import ContentHeroBanner from '@/components/shared/Banner/ContentHeroBanner.vue';
import scrollToTop from '@/mixins/scrollToTop';
import { getOrgContentArray } from '@/utils/data/resources';

const contentData = require('@/data/content/content_pages.json');

export default {
    name: 'SpotlightContent',
    components: {
        ContentHeroBanner,
        NebulaPageWrapper: NebulaGrid.NebulaPageWrapper,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        OrgResourcesRow,
    },
    mixins: [scrollToTop],
    data() {
        return {
            subcategories: [],
            spotlightKey: this.$route.params.spotlight_key.toLowerCase(),
            data: {},
        };
    },
    computed: {
        isSpotlight() {
            if (!this.$store.state.app.spotlight) {
                // if no current spotlight, disregard
                return false;
            }
            return this.spotlightKey === this.$store.state.app.spotlight.key;
        },
    },
    async mounted() {
        this.scrollToTop();
        this.subcategories = getOrgContentArray(this.spotlightKey);
        this.data = contentData[this.spotlightKey];

        await this.$store.dispatch('updateBreadcrumb', {
            dim_text: '',
            link: window.location.pathname,
            text: `${this.data.title} Content`,
        });
    },
};
</script>

<style lang="stylus">
.category-content {
    &__spotlight-title {
        margin-block-start: $nebula-space-5x;
        margin-block-end: 0;
    }
}
</style>
