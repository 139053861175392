<template>
    <PageWrapper :pageTitle="pageTitle" breadcrumbs>
        <ResourcesRow
            v-if="allContent && spotlight"
            class="browse-content__highlight-row"
            :title="spotlight.assetGroupTitle"
            :topic="spotlight.key"
            :max="4"
            :viewAll="{ show: true, link: spotlightViewAll }"
        />
        <ResourcesRow
            v-if="topic"
            :class="{'browse-content__main-grid': !showGeneralTitle}"
            :title="showGeneralTitle ? 'Browse Content' : ''"
            :topic="topic"
            :subcategory="subcategory"
            :max="12"
            paginate
        />
    </PageWrapper>
</template>

<script>
import PageWrapper from '@/components/shared/layout/PageWrapper.vue';
import ResourcesRow from '@/components/resources/ResourcesRow.vue';

import scrollToTop from '@/mixins/scrollToTop';
import { getSemanticKey } from '@/utils/data/resources';
import createPayload from '@/mixins/data/createPayload';

const assetList = require('@/data/assets/org_content.json');

export default {
    name: 'BrowseContent',
    components: {
        // NebulaTitleBlock,
        PageWrapper,
        // ResourcesGrid,
        ResourcesRow,
    },
    mixins: [scrollToTop, createPayload],
    computed: {
        companyData() {
            return this.$store.state.page.organization[this.organizationId];
        },
        allContent() {
            return this.topic === 'all';
        },
        showGeneralTitle() {
            return !!this.allContent && this.spotlight;
        },
        spotlight() {
            return this.$store.state.app.spotlight;
        },
        spotlightViewAll() {
            if (!this.allContent || !this.spotlight) {
                return null;
            }

            const pathBase = this.spotlight.type;

            return `${pathBase}/${this.spotlight.id}`;
        },
    },
    data() {
        return {
            pageTitle: 'Content',
            topic: null,
            subcategory: null,
            subtitle: null,
            organizationId: null,
            breadcrumbPopulated: false,
        };
    },
    async mounted() {
        this.scrollToTop();
        const organizationId = this.$route.params.id;
        const { subcategory } = this.$route.params;

        if (organizationId) {
            this.topic = getSemanticKey(organizationId);
            this.organizationId = organizationId;
        } else {
            this.topic = 'all';
        }

        if (organizationId && subcategory) {
            this.subcategory = subcategory;
            this.pageTitle = assetList[this.topic][subcategory].name;
        }
    },
    beforeDestroy() {
        this.$store.dispatch('resetPageData');
    },
    watch: {
        async companyData() {
            if (!this.breadcrumbPopulated) {
                await this.$store.dispatch('updateBreadcrumb', {
                    dim_text: '',
                    link: window.location.pathname,
                    text: this.pageTitle,
                });

                this.breadcrumbPopulated = true;
            }
        },
    },
};
</script>

<style lang="stylus">
.browse-content {
    &__main-grid {
        margin-block-start: 0;
    }
    &__subtitle {
        --nebula-font-size-header-level-5: $nebula-font-size-card-title;
        margin-block-end: $nebula-space-5x;
    }

    &__highlight-row {
        margin-block-start: 0;
        margin-block-end: $nebula-space-5x;
    }
}
</style>
