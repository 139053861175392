<template>
    <NebulaPageWrapper
        padded
        contained
    >
        <NebulaGridRow type="flex">
            <NebulaColumn
                :columns="6"
                :offset="3"
                :mdSize="8"
                :mdOffset="0"
            >
                <PageHeader title="New Request" />
                <PageSubheader
                    header="Create a New Request"
                    :description="$t('new-request-description', { ns: 'requestcreate'})"
                />
                <RequestForm />
            </NebulaColumn>
        </NebulaGridRow>
    </NebulaPageWrapper>
</template>

<script>
import {
    NebulaGrid,
} from '@discoveryedu/nebula-components';

import PageHeader from '@/components/shared/PageHeader.vue';
import PageSubheader from '@/components/shared/layout/PageSubheader.vue';
import RequestForm from '@/components/shared/requests/RequestForm.vue';

import createPayload from '@/mixins/data/createPayload';

export default {
    name: 'NewRequest',
    components: {
        RequestForm,
        NebulaColumn: NebulaGrid.NebulaColumn,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaPageWrapper: NebulaGrid.NebulaPageWrapper,
        // NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        PageHeader,
        PageSubheader,
    },
    mixins: [createPayload],
    computed: {
    },
    data() {
        return {
        };
    },
    methods: {
    },
};
</script>

<style lang="stylus">
.new-request {
    &__action-row, &__actions {
        @media (min-width: $nebula-breakpoints-tablet-portrait) {
            display: flex;
            justify-content: flex-end;
        }
    }
    &__action-row {
        margin-block-start: $nebula-space-2x;
    }

    &__actions {
        margin-block-start: $nebula-space-4x;
        gap: $nebula-space-2x;
    }

    &__datepicker-label {
        prepop-label();
    }
}
</style>
