<template>
    <div class="subsection">
        <h3 class="subsection__header">
            {{ header }}
        </h3>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'SubSection',
    props: {
        header: String,
    },
};
</script>

<style lang="stylus">
.subsection {
    margin-block-start: $nebula-space-5x;
    margin-inline: auto;
    position: relative;
    width: 100%;

    &:first-child {
        margin-block-start: 0;
    }

    &__header {
        margin: 0 auto $nebula-space-1x;
        nebula-text-body-2();
        font-weight: 600;
        &+p {
            margin-block-start: 0;
        }
    }
}
</style>
