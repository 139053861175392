<template>
    <GrayBoxLoader v-if="loading" card />
    <div v-else>
        <SubSection header="Description">
            <p>{{ request.description }}</p>
        </SubSection>
        <SubSection header="Meeting Link" v-if="showMeetingLink">
            <div v-if="request.meeting_link" class="request-content__copy-group">
                <NebulaIconButton
                    class="request-content__copy-button"
                    size="l"
                    @click="copyToClipboard(request.meeting_link)"
                    type="flat" icon="copy-to-clipboard"
                    aria-label="copy meeting link to clipboard"
                    v-bind="meetingLinkSegmentAttrs"
                />
                <a
                    target="_blank"
                    :href="meetingUrl"
                    class="request-content__meeting-link"
                >
                    {{ request.meeting_link }}
                </a>
                <p v-if="confirmation" class="request-content__copy-confirmation">{{ confirmation }}</p>
            </div>
            <div v-else>
                <p>No additional meeting links were included.</p>
            </div>
        </SubSection>
    </div>
</template>

<script>
import {
    NebulaIconButton,
} from '@discoveryedu/nebula-components';
import SubSection from '@/components/shared/layout/SubSection.vue';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';

import { scheduled, educatorRoles, ROLE_DISPLAY_MAP } from '@/constants';

export default {
    name: 'RequestContent',
    components: {
        NebulaIconButton,
        SubSection,
        GrayBoxLoader,
    },
    props: {
        loading: Boolean,
    },
    data() {
        return {
            confirmation: null,
        };
    },
    computed: {
        request() {
            return this.$store.state.page.request;
        },
        showMeetingLink() {
            const { status, employeeId } = this.request;

            if (educatorRoles.includes(this.appMode)) {
                return true;
            }

            if (status !== scheduled) {
                return false;
            }

            return !!employeeId && employeeId === this.userId;
        },
        meetingUrl() {
            const { meeting_link: meetingUrl } = this.request;
            const supportedPrefixes = ['http://', 'https://'];

            const prefixed = !!supportedPrefixes.find((each) => meetingUrl.toLowerCase().trim().startsWith(each));

            if (prefixed) {
                return meetingUrl;
            }

            return `http://${meetingUrl}`;
        },
        meetingLinkSegmentAttrs() {
            if (!this.appMode) {
                return {};
            }

            const { segmentName = '' } = this.$route.meta;
            const role = ROLE_DISPLAY_MAP[this.appMode.trim().toLowerCase()];
            const type = `${segmentName}: ${role}`;
            const name = 'Copy Link';
            return {
                'data-click-type': type,
                'data-click-name': name,
            };
        },
        appMode() {
            return this.$store.getters.appMode;
        },
        userId() {
            return this.$store.getters.userId;
        },
    },
    methods: {
        copyToClipboard(text) {
            navigator.clipboard.writeText(text);

            this.confirmation = `"${text}" copied to clipboard`;
        },
    },
};
</script>

<style lang="stylus">
.request-content {
    &__copy-confirmation {
        font-size: $nebula-font-size-caption;
        margin-inline-start: $nebula-space-2x;
        margin-block: 0;
    }

    &__copy-group {
        display: flex;
    }

    &__copy-button {
        margin: 0 $nebula-space-2x 0 0;
    }

    &__meeting-link {
        margin: 0;
        link();
    }
}
</style>
