export default {
    data() {
        return {
            loading: {
                request: true,
                applicants: true,
                matches: true,
            },
        };
    },
    computed: {
        requestId() {
            return this.$route.params.id;
        },
        request() {
            return this.$store.getters.request;
        },
        flags() {
            return this.$store.getters.flags;
        },
        requestOwnerId() {
            return this.$store.getters.request.educatorId;
        },
    },
    methods: {
        async getRequests() {
            const payload = await this.createPayload({ requestId: this.requestId });
            await this.$store.dispatch('getRequests', payload);

            this.loading.request = false;
            return null;
        },
        async getSingleRequest() {
            this.loading.request = true;

            const payload = await this.createPayload({ requestId: this.requestId });
            await this.$store.dispatch('getRequests', payload);

            this.loading.request = false;

            if (this.$store.getters.appError) {
                this.$router.push('/');
            }
            return null;
        },
    },
};
