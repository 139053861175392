<template>
    <NebulaTitleBlock
        class="section-title"
        :title="title"
        type="section"
    />
</template>

<script>
import { NebulaTitleBlock } from '@discoveryedu/nebula-components';

export default {
    name: 'SectionTitle',
    props: {
        title: String,
    },
    components: {
        NebulaTitleBlock,
    },
};
</script>

<style lang="stylus">
.section-title {
    card-internal-title();
    font-weight: 400;
    align-items: flex-start;
}
</style>
