<template>
    <NebulaBlockMessage
        class="message-empty-state"
        icon="calendar-add"
        header="New Connect Scheduled!"
        :description="emptyMessage"
    />
</template>

<script>
import { NebulaBlockMessage } from '@discoveryedu/nebula-components';

import { requestTypeDisplay } from '@/utils/data/display';

export default {
    name: 'MessageEmptyState',
    components: {
        NebulaBlockMessage,
    },
    props: {
        requestType: String,
        partnerName: String,
    },
    computed: {
        empty() {
            return Object.keys(this.data).length < 1;
        },
        emptyMessage() {
            return `You can message ${this.partnerName} about your newly scheduled ${this.typeDisplay} connect here.`;
        },
        typeDisplay() {
            return requestTypeDisplay(this.requestType);
        },
    },
};
</script>

<style lang="stylus">
.message-empty-state {
    max-width: 100%;
}
</style>
