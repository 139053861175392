<template>
    <div class="message-thread" :class="{ 'message-thread--empty': empty }">
        <div class="message-thread__inner">
            <GrayBoxLoader v-if="loading" class="message-thread__loader" />
            <MessageEmptyState v-else-if="empty" v-bind="{ requestType, partnerName: partner.displayName }" />
            <div v-else v-for="(day, idx) in dayGroups" :key="`day-${idx}`">
                <MessageDataRow :dateString="data[day].display" />
                <div v-for="(message, msgIdx) in data[day].messages" :key="`message-${msgIdx}`">
                    <MessageCard :data="message" :user="message.self ? self : partner" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';

import MessageCard from '@/components/messaging/MessageCard.vue';
import MessageDataRow from '@/components/messaging/MessageDataRow.vue';
import MessageEmptyState from '@/components/messaging/MessageEmptyState.vue';

export default {
    name: 'MessageThread',
    components: {
        MessageCard,
        MessageDataRow,
        MessageEmptyState,
        GrayBoxLoader,
    },
    props: {
        data: {
            type: Object,
            default() {
                return {};
            },
        },
        self: Object,
        partner: Object,
        requestType: String,
        loading: Boolean,
    },
    computed: {
        dayGroups() {
            return Object.keys(this.data);
        },
        empty() {
            return this.dayGroups.length < 1;
        },
    },
};
</script>

<style lang="stylus">
.message-thread {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;

    &--empty {
        flex-direction: column;
    }
    &__inner {
        padding: $nebula-space-3x;
    }
    &__loader {
        border-radius: $nebula-border-radius-small;
        height: 100px;
    }
}
</style>
