<template>
    <NebulaButtonGroup pill size="s" v-if="show">
        <NebulaButtonGroupItem
            :buttonData="{ text: 'Grid', active: active }"
        />
        <NebulaButtonGroupItem />
    </NebulaButtonGroup>
</template>

<script>
import {
    NebulaButtonGroup,
    NebulaButtonGroupItem,
} from '@discoveryedu/nebula-components';

export default {
    name: 'ViewModeButtons',
    components: {
        NebulaButtonGroup,
        NebulaButtonGroupItem,
    },
    props: {
        active: Boolean,
    },
    computed: {
        show() {
            return false;
        },
    },
};
</script>
