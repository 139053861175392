<template>
    <section class="content-hero-banner" :style="{backgroundImage: `url(${backgroundImage}`}">
        <div class="content-hero-banner__content">
            <BannerTitle :title="data.title" />
            <BannerDescription wide :description="data.description" />
        </div>
    </section>
</template>

<script>
import BannerTitle from '@/components/shared/tokens/Banner/BannerTitle.vue';
import BannerDescription from '@/components/shared/tokens/Banner/BannerDescription.vue';

export default {
    name: 'ContentHeroBanner',
    components: {
        BannerTitle,
        BannerDescription,
    },
    props: {
        data: Object,
        category: String,
    },
    data() {
        return {
            backgroundImage: null,
        };
    },
    mounted() {
        let imgSuffix = '';
        const screenWidth = window.screen.width;
        if (screenWidth < 1200) {
            imgSuffix = '_sm';
        } else if (screenWidth < 2400) {
            imgSuffix = '_md';
        }

        // eslint-disable-next-line global-require, import/no-dynamic-require
        this.backgroundImage = require(`@/assets/category/${this.category}/${this.category}_${this.data.banner.background}${imgSuffix}.jpg`);
    },
};
</script>

<style lang="stylus">
$mobile-content-image-height = 150px;

.content-hero-banner {
    background-position: center $mobile-content-image-height;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: $nebula-border-radius-small;
    min-height: 400px;

    @media (min-width: $nebula-breakpoints-desktop) {
        background-size: 80%;
        background-position: right center;

        min-height: auto;
    }

    &__content {
        gradient-overlay-vertical();
        gradient-border-radius($nebula-border-radius-small, 'vertical');
        padding: $nebula-space-4x;
        padding-block-end: $mobile-content-image-height;
        position: relative;
        z-index: 5;
        @media (min-width: $nebula-breakpoints-desktop) {
            gradient-overlay();
            gradient-border-radius($nebula-border-radius-small);
            max-width: 450px;
            padding: $nebula-space-4x;
        }
    }
}
</style>
